import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { BiCalendarCheck } from "react-icons/bi";
import { RiAttachment2 } from "react-icons/ri";
import { TbMessageSearch } from "react-icons/tb";

function AIModal({ show, handleClose, messages, handleAiResponse }) {
    const [aiResponse, setAiResponse] = useState('');
    const [displayedResponse, setDisplayedResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (aiResponse) {
            const words = aiResponse.trim().split(' ');
            const wordsLength = words.length - 1
            let currentIndex = -1;

            const interval = setInterval(() => {
                if (currentIndex < wordsLength) {
                    setDisplayedResponse(prev => prev + (currentIndex > 0 ? ' ' : '') + words[currentIndex]);
                    currentIndex++;
                } else {
                    clearInterval(interval);
                }
            }, 50);

            return () => clearInterval(interval);
        }
    }, [aiResponse]);

    const analyzeChatWithAI = async (analyze_type) => {
        setIsLoading(true);
        setDisplayedResponse('');

        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/analyze_chat_with_ai', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ messages: messages, analyze_type: analyze_type }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setAiResponse(data.response || '');
        } catch (error) {
            console.error('Error analyzing chat:', error);
            alert('There was an error analyzing the chat. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleRespond = () => {
        if (!displayedResponse.trim()) return;

        handleAiResponse(displayedResponse);
        handleClose();
    };

    const handleModalClose = () => {
        setAiResponse('');
        setDisplayedResponse('');
        setIsLoading(false);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleModalClose} className='modal_AI'>
            <Modal.Body>
                <p className='modal_ai_title'>Kubi<span className='ia_span'>AI</span></p>
                {isLoading || aiResponse ? (
                    <div className='ai_message_cont'>
                        <div className='ai_message'>
                            <div className='ai_img'>
                                <i className='fa-solid fa-box-open'></i>
                            </div>
                            <div className={`ai_response_cont ${isLoading ? 'loading' : ''}`}>
                                <div className='ai_resp_cont'>
                                    <p className='ai_resp'>{displayedResponse}</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className='chat_input_ai_cont'>
                            <div className="chat_input_cont_ai">
                                <input
                                    className="enter_text_chat_input"
                                    placeholder="Scrivi qualcosa..."
                                />
                                <button
                                    className="send_mex_btn_ai"
                                >
                                    <i className="fa-solid fa-paper-plane"></i>
                                </button>
                            </div>
                        </div> */}
                    </div>
                ) : (
                    <div className='functionallities_cont'>
                        <div className='functionallitie analyze_chat' onClick={() => analyzeChatWithAI("book_meeting")}>
                            <i><BiCalendarCheck /></i>
                            <p>Book a meeting</p>
                        </div>

                        <div className='functionallitie analyze_chat' onClick={() => analyzeChatWithAI("perfect_answer")}>
                            <i><TbMessageSearch /></i>
                            <p>Perfect answer</p>
                        </div>

                        <div className='functionallitie analyze_chat' onClick={() => analyzeChatWithAI("analyze_attachments")}>
                            <i><RiAttachment2 /></i>
                            <p>Analyze attachments</p>
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className='close_modal_btn' onClick={handleModalClose}>
                    Close
                </button>
                {aiResponse && (
                    <button className='save_modal_btn' onClick={handleRespond}>
                        Send this message
                    </button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default AIModal;
