import React from "react";
import LLSidebar from "./LLSections/Sidebar/LLSidebar";
import TenantSidebar from "./TenantSection/Sidebar/TenantSidebar";
import { useLanguage } from "../Translations/LenguageContext";

function Sidebar({ onNavigate, userData }) {
    const { t, changeLanguage, language } = useLanguage();

    if (userData) {
        if (userData.user_type === "owner") {
            return (
                <LLSidebar onNavigate={onNavigate} userData={userData}></LLSidebar>
            )
        } else {
            return (
                <TenantSidebar onNavigate={onNavigate} userData={userData}></TenantSidebar>
            )
        }
    } else {
        return (
            <div className="sidebar_cont">
                <div className='loading_mex_cont'>
                    <p className='loading_mex'>{t.loading}</p>
                    <i className='fa-solid fa-box-open'></i>
                </div>;
            </div>
        )
    }
}

export default Sidebar;
