import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Spinner from "../../../components/Spinner";
import { useLanguage } from '../../../Translations/LenguageContext';

// Generate time values in 15-minute intervals in 24-hour format
const generateTimeOptions = () => {
    const times = [];
    let hour = 0;
    let minute = 0;
    const appendZero = num => (num < 10 ? `0${num}` : num);

    while (hour < 24) {
        times.push(`${appendZero(hour)}:${appendZero(minute)}`);
        minute += 15;
        if (minute === 60) {
            minute = 0;
            hour++;
        }
    }
    return times;
};

const timeOptions = generateTimeOptions();

const AddBookingModal = ({ show, handleClose, onBookingAdded, booking }) => {
    console.log(booking)
    const { t } = useLanguage();
    const [invitedEmails, setInvitedEmails] = useState([]);
    const [emailInput, setEmailInput] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [allDay, setAllDay] = useState(false);
    const [bookingType, setBookingType] = useState("");
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const [miniLoading, setMiniLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isAllDayDisabled, setIsAllDayDisabled] = useState(false);
    // const [properties, setProperties] = useState({});
    const [bookingKey, setBookingKey] = useState("");


    useEffect(() => {
        const now = new Date();
        const currentDate = now.toISOString().split('T')[0];
        if (show) {
            const currentMinutes = now.getMinutes();
            const nearestQuarterHour = Math.ceil(currentMinutes / 15) * 15;
            const nearestQuarterHourString = `${now.getHours()}:${nearestQuarterHour === 60 ? '00' : String(nearestQuarterHour).padStart(2, '0')}`;

            setStartDate(currentDate);
            setEndDate(currentDate);

            setStartTime(nearestQuarterHourString);
            setEndTime(nearestQuarterHourString);

            const startIndex = timeOptions.indexOf(nearestQuarterHourString);
            const availableEndTimes = timeOptions.slice(startIndex + 1);

            setEndTime(availableEndTimes.length > 0 ? availableEndTimes[0] : "");
        }

        if (booking) {
            setStartDate(booking.start_date || "");
            setEndDate(booking.end_date || "");
            setStartTime(booking.start_time || "");
            setEndTime(booking.end_time || "");
            setAllDay(booking.all_day || false);
            setBookingType(booking.booking_type || "");
            setInvitedEmails(booking.invited || []);
            setBookingKey(booking.key || "");
        } else {
            setStartDate(currentDate);
            setEndDate(currentDate);
            setStartTime("");
            setEndTime("");
            setAllDay(false);
            setBookingType("");
            setInvitedEmails([]);
            setBookingKey("");
        }
    }, [show, booking]);

    const handleAddEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let errEmail = false;

        if (emailInput === "") {
            setErr({ message: t.register_form.email_format, display: true });
            errEmail = true;
        } else if (!emailRegex.test(emailInput)) {
            setErr({ message: t.register_form.email_format, display: true });
            errEmail = true;
        } else {
            setErr({ message: "", display: false });
            if (emailInput && !invitedEmails.includes(emailInput)) {
                setInvitedEmails([...invitedEmails, emailInput]);
                setEmailInput("");
            }
        }
    };

    const handleRemoveEmail = (emailToRemove) => {
        setInvitedEmails(invitedEmails.filter(email => email !== emailToRemove));
    };

    const handleAllDayChange = (e) => {
        const checked = e.target.checked;
        if (!isAllDayDisabled) {
            setAllDay(checked);
            if (checked) {
                setStartTime(timeOptions[0]);
                setEndTime(timeOptions[timeOptions.length - 1]);
            } else {
                setStartTime("");
                setEndTime("");
            }
        }
    };

    const handleBookingTypeChange = (type) => {
        setBookingType(type);
    };

    const handleStartTimeChange = (e) => {
        const selectedStartTime = e.target.value;
        setStartTime(selectedStartTime);

        const startIndex = timeOptions.indexOf(selectedStartTime);
        const availableEndTimes = timeOptions.slice(startIndex + 1);

        setEndTime(availableEndTimes.length > 0 ? availableEndTimes[0] : "");
    };

    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value);
    };

    const handleStartDateChange = (e) => {
        const selectedStartDate = e.target.value;
        setStartDate(selectedStartDate);
        checkAllDay(selectedStartDate, endDate);
    };

    const handleEndDateChange = (e) => {
        const selectedEndDate = e.target.value;
        setEndDate(selectedEndDate);
        checkAllDay(startDate, selectedEndDate);
    };

    const checkAllDay = (startDate, endDate) => {
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);

            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);

            const oneDayInMillis = 24 * 60 * 60 * 1000;
            const daysDifference = (end - start) / oneDayInMillis;

            if (daysDifference >= 1) {
                setAllDay(true);
                setStartTime(timeOptions[0]);
                setEndTime(timeOptions[timeOptions.length - 1]);
                setIsAllDayDisabled(true);
            } else {
                setAllDay(false);
                setIsAllDayDisabled(false);
            }
        }
    }

    const CheckFields = () => {
        const fields = [
            "title_booking", "start_date_booking", "end_date_booking",
            "start_time_booking", "end_time_booking"
        ];

        let errFields = false;
        let fieldValues = {};
        let errorMessage = "";

        fields.forEach(field => {
            const element = document.getElementById(field);
            if (element) {
                const value = element.value;
                if (value === "") {
                    if (field === "title_booking" || field.includes("date") || field.includes("time")) {
                        element.style.borderBottom = "2px solid var(--danger)";
                    } else {
                        element.style.borderBottom = "2px solid var(--danger)";
                    }
                    errFields = true;
                    fieldValues[field] = null;
                } else if (field.includes("date")) {
                    const startDate = new Date(fieldValues.start_date_booking || value);
                    const endDate = new Date(fieldValues.end_date_booking || value);
                    if (field === "end_date_booking" && startDate > endDate) {
                        element.style.borderBottom = "2px solid var(--danger)";
                        errorMessage = "End date cannot be before start date";
                        errFields = true;
                        fieldValues[field] = null;
                    } else {
                        element.style.border = "";
                        element.style.borderBottom = "";
                        fieldValues[field] = value;
                    }
                } else if (field === "invited_booking") {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegex.test(value)) {
                        element.style.borderBottom = "2px solid var(--danger)";
                        errorMessage = "Invalid email format";
                        errFields = true;
                        fieldValues[field] = null;
                    } else {
                        element.style.border = "";
                        element.style.borderBottom = "";
                        fieldValues[field] = value;
                    }
                } else {
                    element.style.border = "";
                    element.style.borderBottom = "";
                    fieldValues[field] = value;
                }
            } else {
                fieldValues[field] = null;
            }
        });

        if (bookingType === "") {
            document.querySelectorAll('.booking_type_btn').forEach(btn => {
                btn.style.border = "2px solid var(--danger)";
            });
            errFields = true;
        } else {
            document.querySelectorAll('.booking_type_btn').forEach(btn => {
                btn.style.border = "";
            });
        }

        if (errFields) {
            setErr({ message: errorMessage || t.register_form.red_fields, display: true });
        } else {
            setErr({ message: "", display: false });
            setLoading(true);
            SaveData(
                fieldValues.title_booking, fieldValues.start_date_booking, fieldValues.end_date_booking,
                fieldValues.start_time_booking, fieldValues.end_time_booking,
                fieldValues.position_booking, fieldValues.description_booking
            );
        }
    };

    const SaveData = (title_booking, start_date_booking, end_date_booking, start_time_booking, end_time_booking, position_booking, description_booking) => {
        const userId = sessionStorage.getItem("user_id");

        const dataToSave = {
            user_id: userId,
            title: title_booking,
            booking_type: bookingType,
            start_date: start_date_booking,
            end_date: end_date_booking,
            start_time: start_time_booking,
            end_time: end_time_booking,
            invited: invitedEmails,
            position: position_booking,
            description: description_booking,
            key: bookingKey,
        };

        fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/add_update_booking', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSave),
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.message === "OK") {
                    setErr({ message: "", display: false });
                    CleanField();
                    onBookingAdded();
                } else if (data.error) {
                    setErr({ message: t.register_form.errors_back, display: true });
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
                setErr({ message: t.register_form.problem_back, display: true });
            });
    };

    function CleanField() {
        const fields = [
            "title_booking", "start_date_booking", "end_date_booking",
            "start_time_booking", "end_time_booking",
            "invited_booking", "position_booking", "description_booking"
        ];

        fields.forEach(field => {
            const element = document.getElementById(field);
            if (element) {
                element.style.borderBottom = "";
                element.value = "";
            }
        });

        setInvitedEmails([]);
        setEmailInput("");
        setBookingType("");
        setAllDay(false);
        setIsAllDayDisabled(false);
        setErr({ message: "", display: false });
        setLoading(false);
        setBookingKey("");
        handleClose();
    }

    const bookingTypeDisplayNames = {
        // short_term: t.booking.short_term,
        // long_term: t.booking.long_term,
        personal: t.booking.personal,
        meeting: t.booking.meeting,
        visit: t.booking.visit,
        second_visit: t.booking.second_visit,
        contract_sign: t.booking.contract_sign,
        work: t.booking.work,
        other: t.booking.other
    };


    const DeleteBooking = () => {
        setLoading(true)
        const userId = sessionStorage.getItem("user_id");

        if (!userId || !bookingKey) {
            console.error('User ID or booking key is missing');
            return;
        }

        const dataToSend = {
            user_id: userId,
            key: bookingKey,
        };

        fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/delete_booking', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false)
                if (data.message === "OK") {
                    console.log('Booking deleted successfully');
                    CleanField()
                    onBookingAdded();
                } else if (data.error) {
                    setErr({ message: t.register_form.errors_back, display: true });
                }
            })
            .catch(error => {
                setErr({ message: t.register_form.errors_back, display: true });
            });
    };


    return (
        <Modal show={show} onHide={CleanField} className="modal_booking">
            <div className='header_modal'>
                <div className='modal_title_cont'>
                    <i className='fa-solid fa-plus'></i>
                    <input id='title_booking' className='modal_title' placeholder={t.booking.add_title} defaultValue={booking?.title || ''}></input>
                </div>
                <button className='delete_record_btn' onClick={DeleteBooking}>
                    <i className='fa-solid fa-trash'></i>
                </button>
            </div>
            <div className='body_modal'>
                <div className='body_input_cont_modal'>
                    <p className='booking_element_title'>{t.booking.booking_type}</p>
                    <div className='booking_type'>
                        {Object.entries(bookingTypeDisplayNames).map(([type, displayName]) => (
                            <button
                                key={type}
                                style={{ border: bookingType === type ? '2px solid var(--mainColor)' : '', color: bookingType === type ? 'var(--mainColor)' : '' }}
                                className="booking_type_btn"
                                onClick={() => handleBookingTypeChange(type)}
                            >
                                {displayName}
                            </button>
                        ))}
                    </div>
                    <p className='booking_element_title'>{t.booking.date_time}</p>
                    <div className='booking_date_cont'>
                        <div className='date_hour_cont_booking'>
                            <input
                                id='start_date_booking'
                                className='date_hour_modal_input'
                                type='date'
                                value={startDate}
                                onChange={handleStartDateChange}
                            />
                            <span className='divider_span_modal'>-</span>
                            <input
                                id='end_date_booking'
                                className='date_hour_modal_input'
                                type='date'
                                value={endDate}
                                onChange={handleEndDateChange}
                            />
                            <span className='divider_span_modal'>/</span>
                            <select
                                id='start_time_booking'
                                className='date_hour_modal_input'
                                value={startTime}
                                onChange={handleStartTimeChange}
                                disabled={allDay}
                            >
                                <option value="">-</option>
                                {timeOptions.map((time, index) => (
                                    <option key={index} value={time}>{time}</option>
                                ))}
                            </select>
                            <span className='divider_span_modal'>-</span>
                            <select
                                id='end_time_booking'
                                className='date_hour_modal_input'
                                value={endTime}
                                onChange={handleEndTimeChange}
                                disabled={allDay}
                            >
                                <option value="">-</option>
                                {timeOptions.filter(time => time > startTime).map((time, index) => (
                                    <option key={index} value={time}>{time}</option>
                                ))}
                            </select>
                        </div>
                        <div className='label_checkbox_cont date_hour_modal_input'>
                            <label className='label_checkbox'>{t.booking.all_day}</label>
                            <input
                                type='checkbox'
                                className='input_checkbox'
                                checked={allDay}
                                onChange={handleAllDayChange}
                                disabled={isAllDayDisabled}
                            />
                        </div>
                    </div>
                    <p className='booking_element_title'>{t.booking.partecipants}</p>
                    <div className='booking_invited_cont'>
                        <div className='invited_input_btn_cont_modal'>
                            <input
                                id='invited_booking'
                                className='invited_input_booking_modal'
                                placeholder='name@exemple.com'
                                value={emailInput}
                                onChange={(e) => setEmailInput(e.target.value)}
                            />
                            <button className='add_invited_btn' onClick={handleAddEmail}>
                                <i className='fa-solid fa-plus'></i>
                            </button>
                        </div>
                        <div className='invited_boocking_users_cont'>
                            {invitedEmails.map((email, index) => (
                                <div key={index} className='invited_boocking_users'>
                                    <p>{email}</p>
                                    <i className='fa-solid fa-x' onClick={() => handleRemoveEmail(email)}></i>
                                </div>
                            ))}
                        </div>
                    </div>
                    <p className='booking_element_title'>{t.booking.location}</p>
                    <div className='booking_location_cont'>
                        <input id='position_booking' className='position_booking_modal_input' placeholder='Via e numero' defaultValue={booking?.position || ''}></input>
                    </div>
                    <p className='booking_element_title'>{t.booking.description}</p>
                    <div className='booking_description_cont'>
                        <textarea id='description_booking' className='description_booking_modal_input' placeholder={t.booking.add_description} defaultValue={booking?.description || ''}></textarea>
                    </div>
                </div>
            </div>
            <div className='footer_modal'>
                <div className='footer_btn_cont'>
                    <button className='save_modal_btn' onClick={CheckFields}>
                        {t.booking.save_btn}
                    </button>
                    <button className='close_modal_btn' onClick={CleanField}>
                        {t.booking.close_btn}
                    </button>
                </div>
                <Spinner loading={loading} err={err}></Spinner>
            </div>
        </Modal>
    );
};

export default AddBookingModal;
