import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import PropertiesList from "./PropertiesList";
import MonthlyPaymentsChoice from "./MonthlyPaymentsChoice";
import { useNavigate } from "react-router-dom";
import TenantPersonalInformation from "./TenantPersonalInformation";
import TenantPaymentMethods from "./TenantPaymentMethods";

const NewTenant = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [properties, setProperties] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const userId = sessionStorage.getItem("user_id");

    const fetchProperties = async () => {
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_properties', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProperties(data.properties || {});
        } catch (error) {
            setError(error.message || 'Failed to fetch properties');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, [userId]);

    const handlePropertySelection = (property) => {
        setSelectedProperty(property);
    };

    const BackToPreviousPage = () => {
        navigate("/control_panel/tenants");
    };

    
    if (loading) return (
        <div className='loading_mex_cont'>
            <p className='loading_mex'>{t.loading}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );
    
    if (error) return (
        <div className='loading_mex_cont_err'>
            <p>{t.error}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    return (
        <div className="main_view_section">
            <p className="title_main_view">
                <i
                    onClick={BackToPreviousPage}
                    className="fa-solid fa-chevron-left back-btn_chevron"
                ></i>
                New tenant
            </p>
            <div className="new_tenant_form">
                <div className="tenant_label_input_cont">
                    <p className="new_tenant_title">Available properties for rent</p>
                    <p className="description_tenant_title">Choose between the available properties</p>
                    <div className="property_choice_cont">
                        <PropertiesList
                            fetchProperties={fetchProperties}
                            properties={properties}
                            onPropertySelect={handlePropertySelection}
                        />
                        <MonthlyPaymentsChoice
                            selectedProperty={selectedProperty}
                        />
                    </div>
                </div>
                <TenantPersonalInformation />
                <TenantPaymentMethods />
            </div>
        </div>
    );
};

export default NewTenant;
