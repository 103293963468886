import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function RegistrationComplete() {
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);

    const NavigateToHome = () => {
        navigate("/control_panel/home");
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    NavigateToHome();
                    return 100;
                }
                return prevProgress + 2;
            });
        }, 100);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="registration_complete_cont">
            <div className="registr_complete">
                <p className="registr_title">Puoi iniziare a ricevere pagamenti!</p>
                <p className="register_parag">
                    Il tuo account <span className="ia_span">Stripe</span> è stato creato e collegato con successo.
                </p>

                <button
                    className="back_to_home_btn"
                    onClick={NavigateToHome}
                    style={{
                        backgroundSize: `${progress}% 100%`, // Background grows horizontally
                        backgroundImage: "linear-gradient(to right, var(--mainColor), var(--mainColor))", // Green gradient for progress
                    }}
                >
                    Torna sulla dashboard
                </button>
            </div>
        </div>
    );
}

export default RegistrationComplete;
