import React from "react";

function Balance() {
    return (
        <div className="main_view_section">
            <p className="title_main_view">Balance</p>
        </div>
    )
}

export default Balance