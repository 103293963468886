import React, { useState } from 'react';
import { useLanguage } from "../../../Translations/LenguageContext";
import { BiCircle, BiCheckCircle } from "react-icons/bi";
import { BsHouses } from "react-icons/bs";
import { Dropdown } from 'react-bootstrap';
import DeletePropertyModal from './DeletePropertieModal';
import AddPropertieModal from './AddPropertieModal';

function PropertiesMain({ properties, onPropertyDeleted, onPropertieAddedOrUpdated }) {
    const { t } = useLanguage();
    const [selectedPropertyKey, setSelectedPropertyKey] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedPropertyName, setSelectedPropertyName] = useState(null);
    const [selectedPropertyData, setSelectedPropertyData] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [popupType, setPopupType] = useState("success");
    const [popupActive, setPopupActive] = useState(false);
    const [userId, setUserId] = useState(sessionStorage.getItem("user_id"));

    const getStatus = (property) => {
        switch (property.available) {
            case 'available_rent':
                return {
                    text: t.properties.available_rent,
                    icon: <BiCircle />
                };
            case 'rented':
                return {
                    text: t.properties.rented,
                    icon: <BiCheckCircle />
                };
            case 'available_buy':
                return {
                    text: t.properties.available_buy,
                    icon: <BiCircle />
                };
            case 'sold':
                return {
                    text: t.properties.sold,
                    icon: <BiCheckCircle />
                };
            default:
                return {
                    text: t.properties.available_notAvailable,
                    icon: <BiCircle />
                };
        }
    };

    const handleEditClick = (key, property) => {
        setSelectedPropertyKey(key);
        setSelectedPropertyData(property);
        setShowAddModal(true);
    };

    const handleDeleteClick = (key, propertyName) => {
        setSelectedPropertyKey(key);
        setShowDeleteModal(true);
        setSelectedPropertyName(propertyName);
    };

    const handleDeleteConfirmed = async () => {
        setShowDeleteModal(false);
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/delete_property', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId, property_key: selectedPropertyKey }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete property');
            }

            onPropertyDeleted(selectedPropertyKey); // Notify parent of deletion

            setPopupType("success");
            setPopupActive(true);
        } catch (error) {
            setPopupType("error");
            setPopupActive(true);
            console.error(error);
        }
    };

    const handlePropertyAddedOrUpdated = () => {
        setShowAddModal(false);
        setPopupType("success");
        setPopupActive(true);
    };

    return (
        <div className='property_table_container'>
            <div className='property_table_container_title'>
                <i><BsHouses /></i>
                {t.properties.my_properties}
            </div>
            <div className='property_table_container_cont'>
                {properties.length > 0 ? (
                    properties.map(([key, property]) => {
                        const { text, icon } = getStatus(property);

                        return (
                            <div className='property_details' key={key}>
                                <div className='property_img_cont'>
                                    {property.files && property.files.length > 0 ? (
                                        <div
                                            className='property_img'
                                            style={{
                                                overflowY: property.files.length > 1 ? 'scroll' : 'hidden',
                                            }}
                                        >
                                            {property.files.map((image, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        backgroundImage: `url(${image.url})`,
                                                    }}
                                                    alt={image.filename}
                                                    className='property_img_image'
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <div className='property_img_no_image'>
                                            <p>{t.properties.no_images}</p>
                                        </div>
                                    )}
                                </div>
                                <div className='property_body'>
                                    <div className='body_prop1'>
                                        <div className='prop_title'>
                                            <span className='title_prop_span title'>
                                                <p className='property_title'>{property.title_property}</p>
                                                <p className='property_unit_type'>{t?.properties?.[property.unit_type]}</p>
                                            </span>
                                            <span className='title_prop_span location'>
                                                <i className='fa-solid fa-map-pin'></i>
                                                <p>{property.nation_property} - {property.city_property} - {property.street_property}, {property.number_property} - {property.zip_property}</p>
                                            </span>
                                        </div>
                                        <div className='body_prop_price'>
                                            <div className='body_prop_price_cont'>
                                                <p className={`available_prop ${['rented', 'sold'].includes(property.available) ? 'available' : 'non_available'}`}>
                                                    {text}
                                                    <i>{icon}</i>
                                                </p>
                                                <p className='prop_price'>
                                                    <span>m/</span>
                                                    {(
                                                        (Number(property.price_property) || 0) +
                                                        (Number(property.expenses_property) || 0)
                                                    ).toFixed(2)} {property.currency_property}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='body_prop2'>
                                        <div className='det_prop_cont'>
                                            {property.mt2_property && property.mt2_property !== "0" && (
                                                <div className='det_prop'>
                                                    <p className='det_desc'>
                                                        <i className='fa-solid fa-vector-square'></i> {property.mt2_property} <span className='small_p'>mt2</span>
                                                    </p>
                                                </div>
                                            )}
                                            {property.living_property && property.living_property !== "0" && (
                                                <div className='det_prop'>
                                                    <p className='det_desc'>
                                                        <i className='fa-solid fa-couch'></i>
                                                        {property.living_property}
                                                    </p>
                                                </div>
                                            )}
                                            {property.bedroom_property && property.bedroom_property !== "0" && (
                                                <div className='det_prop'>
                                                    <p className='det_desc'>
                                                        <i className='fa-solid fa-bed'></i>
                                                        {property.bedroom_property}
                                                    </p>
                                                </div>
                                            )}
                                            {property.kitchen_property && property.kitchen_property !== "0" && (
                                                <div className='det_prop'>
                                                    <p className='det_desc'>
                                                        <i className='fa-solid fa-fire-burner'></i>
                                                        {property.kitchen_property}
                                                    </p>
                                                </div>
                                            )}
                                            {property.bathroom_property && property.bathroom_property !== "0" && (
                                                <div className='det_prop'>
                                                    <p className='det_desc'>
                                                        <i className='fa-solid fa-bath'></i>
                                                        {property.bathroom_property}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <div className='btn_prop_container'>
                                            <div className='btn_prop_cont'>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        className='custom-dropdown-toggle'
                                                        as="div"
                                                    >
                                                        <i className='fa-solid fa-ellipsis-vertical'></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='custom-dropdown-menu'>
                                                        <Dropdown.Item
                                                            className='drop_down_item'
                                                            onClick={() => handleEditClick(key, property)}
                                                        >
                                                            <i className='fa-solid fa-pencil'></i> {t.edit}
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Item
                                                            className='drop_down_item'
                                                        >
                                                            <i className='fa-solid fa-pencil'></i> Pubblica annuncio
                                                        </Dropdown.Item> */}
                                                        <Dropdown.Item
                                                            className='drop_down_item delete'
                                                            onClick={() => handleDeleteClick(key, property.title_property)}
                                                        >
                                                            <i className='fa-solid fa-trash'></i> {t.delete}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className='no_data_mex_cont'>
                        <p className='no_data_mex'>{t.properties.no_property}</p>
                        <div className='no_data_img'></div>
                    </div>
                )}
            </div>
            <DeletePropertyModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                onDeleteConfirmed={handleDeleteConfirmed}
                propertyName={selectedPropertyName}
            />
            <AddPropertieModal
                show={showAddModal}
                handleClose={() => setShowAddModal(false)}
                onPropertieAddedOrUpdated={onPropertieAddedOrUpdated}
                propertyData={selectedPropertyData}
            />
        </div>
    );
}

export default PropertiesMain;
