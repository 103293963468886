import React from "react";

function TenantPayments(){
    return(
        <div className="main_view_section">
            Payments
        </div>
    )
}

export default TenantPayments