import React, { useState } from "react";
import StepsIndicator from "./StepsIndicator";
import MainSteps from "./MainSteps";

function MainStepsCont() {
    const [currentStep, setCurrentStep] = useState(1); 

    return (
        <div className="sec_complete_reg_cont">
            <StepsIndicator currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <MainSteps currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </div>
    );
}

export default MainStepsCont;
