import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";

const MonthlyPaymentsChoice = ({ selectedProperty }) => {
    const { t } = useLanguage();

    const [monthlyPayments, setMonthlyPayments] = useState({});
    const [totalYearlyAmount, setTotalYearlyAmount] = useState(0);

    const [currency, setCurrency] = useState("");
    const [commissionCost, setCommissionCost] = useState(0);

    useEffect(() => {
        if (selectedProperty) {
            const monthlyAmountPrice = selectedProperty.price_property || 0;
            const monthlyAmountExpenses = selectedProperty.expenses_property || 0;
            const monthlyAmountDeposit = selectedProperty.deposit_property || 0;
            const monthlyAmountCommission = selectedProperty.commissions_property || 0;
            const monthlyAmountCommissionType = selectedProperty.commission_type_property || "";
            const monthlyAmountCurrency = selectedProperty.currency_property || "";

            let calculatedCommissionCost;
            if (["€", "$", "£"].includes(monthlyAmountCommissionType)) {
                calculatedCommissionCost = Number(monthlyAmountCommission);
            } else {
                calculatedCommissionCost = ((Number(monthlyAmountPrice) * 12) * Number(monthlyAmountCommission)) / 100;
            }

            setCommissionCost(calculatedCommissionCost);
            setCurrency(monthlyAmountCurrency);

            const initialPayments = Object.keys(t.months).reduce((acc, monthKey) => {
                acc[monthKey] = Number(monthlyAmountPrice) + Number(monthlyAmountExpenses);
                return acc;
            }, {});

            setMonthlyPayments(initialPayments);
        }else{
            setMonthlyPayments("")
            setTotalYearlyAmount("")
            setCurrency("")
            setCommissionCost("")
        }
    }, [selectedProperty, t.months]);

    useEffect(() => {
        const total = Object.values(monthlyPayments).reduce((sum, monthValue) => sum + Number(monthValue), 0);
        setTotalYearlyAmount(total);
    }, [monthlyPayments]);

    const handleInputChange = (monthKey, value) => {
        setMonthlyPayments(prev => ({
            ...prev,
            [monthKey]: Number(value)
        }));
    };

    return (
        <div className="rent_ammount_choise">
            <p className="monthly_payment_title">Importi mensili</p>
            {Object.keys(t.months).map((monthKey) => (
                <div key={monthKey} className="monthly_payment">
                    <label className="monthly_payment_label">{t.months[monthKey]}</label>
                    <input
                        className="monthly_payment_input"
                        type="number"
                        value={monthlyPayments[monthKey] || 0}
                        onChange={(e) => handleInputChange(monthKey, e.target.value)}
                    />
                    <p className="currency_monthly_payment">{currency}</p>
                </div>
            ))}
            <p className="total_value">Incasso annuale: {totalYearlyAmount}{currency}</p>
        </div>
    );
};

export default MonthlyPaymentsChoice;
