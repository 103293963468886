import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home"
import Bookings from "./LLSections/Booking/Bookings";
import Properties from "./LLSections/Property/Properties";
import Contacts from "./LLSections/Contacts/Contacts";
import Messages from "./LLSections/Messages/Messages";
import Transactions from "./LLSections/Transactions";
import Balance from "./LLSections/Balance";
import Report from "./LLSections/Report";
import TenantAnalysis from "./LLSections/TenantAnalysis";
import MarketPricing from "./LLSections/MarketPricing";
import ExpensesForecast from "./LLSections/ExpensesForecast";
import Profile from "./LLSections/Profile/Profile";
import Settings from "./LLSections/Settings/Settings";
import Notifications from "./LLSections/Notifications";
import WBManager from "./LLSections/WebsiteBuilder/WB_Manager";
import TenantPayments from "./TenantSection/Payments/Payments";
import LLPayments from "./LLSections/Payments/Payments";
import Tenants from "./LLSections/Tenants/Tenants";
import NewTenant from "./LLSections/Tenants/NewTenant";

function MainView({ userData, no_session }) {
  return (
    <div className="main_view_container">
      <Routes>
        <Route path="home" element={<Home userData={userData} no_session={no_session} />} />
        <Route path="bookings" element={<Bookings />} />
        <Route path="properties" element={<Properties />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="messages" element={<Messages />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="balance" element={<Balance />} />
        <Route path="report" element={<Report />} />
        <Route path="tenant-analysis" element={<TenantAnalysis />} />
        <Route path="market-pricing" element={<MarketPricing />} />
        <Route path="expenses-forecast" element={<ExpensesForecast />} />
        <Route path="profile" element={<Profile />} />
        <Route path="settings" element={<Settings />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="website-builder" element={<WBManager />} />
        <Route path="tenant-payment" element={<TenantPayments />} />
        <Route path="owner-payments" element={<LLPayments />} />
        <Route path="tenants" element={<Tenants />} />
        <Route path="tenants/new-tenant" element={<NewTenant />} />
      </Routes>
    </div>
  );
}

export default MainView;
