// OnBoardingSection.js
import React, { useEffect, useState } from "react";
import { useLanguage } from "../Translations/LenguageContext";
import { FaRegUser, FaRobot, FaFileAlt, FaCreditCard } from "react-icons/fa";

function ManageCalendar() {
    const { t } = useLanguage();

    return (
        <div className="calendar_manage_container">
            <div className="calendar_process">
                <p className="steps_title">{t.manage_calendar.title}</p>
                <p className="steps_desc">{t.manage_calendar.desc}</p>
            </div>
            <div className="cal_cont">
                <div className="cal_sec">
                    <div className="cal_desc_cont">
                        <div className="cal_desc_title">
                            <p>{t.manage_calendar.cal_desc_title}</p>
                        </div>
                        <div className="cal_desc">
                            <p>{t.manage_calendar.cal_desc}</p>
                        </div>
                    </div>

                </div>
                <div className="cal_sec">
                    <div className="long_term_img">

                    </div>

                </div>
            </div>
        </div>


    )
}

export default ManageCalendar;