import React, { useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";

function TenantPaymentMethods() {
    const { t } = useLanguage();
    const [isSEPAEnabled, setIsSEPAEnabled] = useState(null); // Track the state of the checkboxes

    const handleCheckboxChange = (value) => {
        setIsSEPAEnabled(value);
    };

    return (
        <div className="tenant_label_input_cont">
            <p className="new_tenant_title">Payment configuration</p>
            <p className="description_tenant_title">Choose between the payment methods available</p>
            <div className="tenant_payment_method_container">
                <div className="tenant_info_cont">
                    <div className="tenant_payment_input_cont">
                        <label className="payment_tenant_label">Abilitare pagamenti SEPA mensili ricorrenti?</label>
                        <div className="checkbox_cont">
                            <div className="checbox_input_cont">
                                <label>Si</label>
                                <input
                                    type="checkbox"
                                    checked={isSEPAEnabled === true}
                                    onChange={() => handleCheckboxChange(true)}
                                />
                            </div>
                            <div className="checbox_input_cont">
                                <label>No</label>
                                <input
                                    type="checkbox"
                                    checked={isSEPAEnabled === false}
                                    onChange={() => handleCheckboxChange(false)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="tenant_payment_input_cont">
                        <label className="payment_tenant_label">Come vuoi gestire le commissioni verso terzi?</label>
                        <select className="tenant_input">
                            <option value="">-</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TenantPaymentMethods;
