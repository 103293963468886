import React from "react";
import { useLanguage } from "../../../Translations/LenguageContext"; 

function Settings() {
    const { t, changeLanguage, language } = useLanguage();

    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.settings.settings_title}</p>
            <div className="settings">
                <div className="section_settings">
                    <p className="section_settings_title">Impostazioni</p>
                    <div className="link_app_container">
                        <div className="app_link">
                            <i className="fa-solid fa-shop"></i>
                            <div className="link_app_desc">
                                <p className="title_link_app_desc">Attività</p>
                                <p className="desck_link_app">Gestisci la tua attività immobiliare</p>
                            </div>
                        </div>

                        <div className="app_link">
                            <i className="fa-solid fa-file"></i>
                            <div className="link_app_desc">
                                <p className="title_link_app_desc">Coformità e documenti</p>
                                <p className="desck_link_app">Conformità dei documenti, privacy policy, cookies, gestione dati e molto altro</p>
                            </div>
                        </div>

                        <div className="app_link">
                            <i className="fa-solid fa-user"></i>
                            <div className="link_app_desc">
                                <p className="title_link_app_desc">Profilo</p>
                                <p className="desck_link_app">Dati personali, password, email, activity log. </p>
                            </div>
                        </div>

                        <div className="app_link">
                            <i className="fa-solid fa-dashboard"></i>
                            <div className="link_app_desc">
                                <p className="title_link_app_desc">Il tuo piano</p>
                                <p className="desck_link_app">Gestisci come paghi FlatJourney <span className="ia_span">AI</span></p>
                            </div>
                        </div>

                        <div className="app_link">
                            <i className="fa-solid fa-code"></i>
                            <div className="link_app_desc">
                                <p className="title_link_app_desc">Sviluppatori</p>
                                <p className="desck_link_app">API, Database, Website Builder...</p>
                            </div>
                        </div>
                    </div>
                    

                </div>
               
            </div>
        </div>
    )
}

export default Settings