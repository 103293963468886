export const translations = {
    en: {
        brand_logo: "FlatJourney AI",
        main_title: "Streamline your Real Estate Business with",
        ai: " AI",
        main_description: "From Onboarding to Payments, all in One Place",
        property_management: "Property Management",
        tenant_oversight: "Tenant Oversight",
        secure_transactions: "Secure Transactions",
        instant_messaging: "Instant Messaging",
        email_notifications: "Email Notifications",
        calendar_events: "Calendar Events",
        ai_driven_analytics: "AI-Driven Analytics",
        start_now: "START NOW",
        login: "Login",
        edit: "Edit",
        delete: "Delete",
        yes: "Yes",
        no: "No",
        register_form: {
            name: "Name",
            surname: "Surname",
            phone: "Phone",
            repeat_password: "Repeat password",
            button_register: "Sign Up",
            already_registered: "Already registered?",
            go_login: "Login",
            email_format: "The email format is incorrect...",
            password_err: "Password must be at least 10 characters long and contain uppercase letters, lowercase letters, special characters, and numbers..",
            password_match: "Passowrds don't match...",
            red_fields: "Check the required fields...",
            email_already_present: "Email already present...",
            errors_back: "An error was detected...retry",
            problem_back: "There is a problem with the access...rety in a few minutes"
        },
        login_form: {
            button_login: "Login",
            dont_have_account: "Don't have an account?",
            go_register: "Register",
            invalid_email_pass: "invalid email or password...retry"
        },
        onboarding: {
            title: "Master Every Stage of Your Journey",
            desc: "Seamlessly manage and oversee every aspect from start to finish.",
            titles: [
                "Onboarding",
                "AI Validation",
                "Document Filling",
                "Automatic Payments"
            ],
            descriptions: [
                "Onboarding helps you get started quickly with a smooth introduction to our system.",
                "AI Validation ensures that all your data is accurate and meets our standards.",
                "Document Filling simplifies the process of completing and submitting necessary paperwork.",
                "Automatic Payments make transactions easy and hassle-free with automatic billing."
            ],
            icons: [
                "FaRegUser",
                "FaRobot",
                "FaFileAlt",
                "FaCreditCard"
            ]
        },
        now: "now",
        loading: "Loading...",
        error: "Something went wrong...",
        payment_process: {
            title: "Optimize and Automate Payment Processes",
            desc: "Ensure smooth and hassle-free payment transactions."
        },
        payment_notifications: {
            notification1: "Aisha Thompson paid her rent!",
            notification2: "John Doe paid his rent!",
            notification3: "Jack Miller paid his rent!",
        },
        secure_payments: {
            title: "Secure Payments",
            description: "FlatJourney is securely backed by Stripe, ensuring safe and reliable transactions for all your rental payments."
        },
        manage_calendar: {
            title: "Manage Long and Short-Term Rentals",
            desc: "Effortlessly handle bookings and scheduling.",
            cal_desc_title: "Comprehensive Property Management Platform",
            cal_desc: "Efficiently manage both long-term and short-term rental bookings from a single platform. Whether your tenants are on vacation or residing in your city, streamline scheduling, management, and service delivery with ease."
        },
        footer: {
            about_us: "About Us",
            who: "Company",
            jobs: "Open positions",
            get_in_touch: "Get in touch",
            terms_conditions: "Terms & Conditions",
            legal_notes: "Legal notes",
            brand_rights: "© 2024 FlatJourney AI.",
            address: "Via EG 5, 20145, Milano MI",
            vat: "VAT: 123456789",
        },
        tll: {
            question: "Are you the property owner or the person renting the space?",
            resp_t: "I'm renting the space",
            resp_pm: "I'm the property owner",
        },
        sidebar: {
            home: "Home",
            bookings: "Calendar",
            properties: "Properties",
            contacts: "Contacts",
            messages: "Messages",
            website_builder: "Website builder",
            transactions: "Transactions",
            balance: "Balance",
            report: "Report",
            tenant_analysis: "Tenant analysis",
            market_pricing: "Market pricing",
            expenses_forecast: "Expenses forecast",
            coming_soon_mex: "Coming up!",
            payments: "Payments",
        },
        complete_account: {
            title: "Complete configuration",
            description: "Complete all necessary setup steps to finalize the configuration",
            completed_steps: "Completed steps",
            complete_btn: "Complete",
            not_now_btn: "Do it later",
            personal_info: "Personal information",
            business_info: "Business information",
            deposit_account: "Deposit account",
            birthday: "Birthday",
            sex: "Sex",
            country: "Country",
            city: "City",
            province: "Province",
            street: "Street",
            n: "No.",
            zip: "Zip",
            business_name: "Business Name",
            vat: "VAT",
            stripe_title: "FlatJourneyAI Partners with Stripe for Seamless Payments!",
            stripe_desc: "Effortlessly create and connect your Stripe account to start receiving payments without any hassle.",
            stripe_button: "Setup Stripe account",
            process_loading: "Processing...",
            sepa: "SEPA allows for automatic payments, like recurring rent or subscriptions, directly from a bank account. It simplifies cross-border transfers to be as fast, reliable, and cost-effective as local transactions, ensuring a secure and seamless experience for both businesses and customers.",
            card_payment: "Accepting credit and debit card payments ensures fast, reliable rent collection with instant transactions and seamless integration into your management system. It's ideal for landlords who prioritize speed and convenience.",
            sepa_fee_1: "€1.50 for payments under €400",
            sepa_fee_2: "€2.50 for payments under €400",
            card_fee_1: "2.5% + €0.25 for standard EU cards",
            card_fee_2: "2.9% + €0.25 for premium EU cards"
        },
        popup: {
            success: "Saved successfully",
            error: "An error occurred"
        },
        booking: {
            date_leng: "en-GB",
            no_bookings: "No bookings available...",
            appointments: "Appointments",
            date_order: "Start date can't be grater than End date...",
            add_booking: "Add booking",
            add_title: "Add title...",
            booking_type: "Booking type",
            date_time: "Date and hour",
            all_day: "All day",
            partecipants: "Partecipants",
            location: "Location",
            description: "Description",
            add_description: "Add a brief description...",
            short_term: "Short Term",
            long_term: "Long Term",
            personal: "Personal",
            meeting: "Meeting",
            visit: "Visit",
            second_visit: "Second visit",
            contract_sign: "Contract sign",
            work: "Work",
            other: "Other",
            save_btn: "Save booking",
            close_btn: "Close",
        },
        properties: {
            my_properties: "My properties",
            no_property: "No properties available...",
            add_property: "Add property",
            apply_filters: "Apply filters",
            filter_btn: "Filter",
            title_main: "Title",
            title_prop: "Add a name",
            unit_type: "Unit type",
            nation: "Nation",
            city: "City",
            street_num: "Street and number",
            zip: "CAP",
            prop_type: "Property type",
            mt2: "Square meters - mt2",
            floor: "Floor",
            building_floors: "Total building floors",
            energy_class: "Energy class",
            exposure: "Exposure",
            heating: "Heating",
            external_fixtures: "External fixtures",
            prop_state: "Property state",
            construction_year: "Construction year",
            living: "Living space",
            bedroom: "Bedroom",
            kitchen: "Kitchen",
            bathroom: "Bathroom",
            other_included_details: "Other included details",
            price: "Property price",
            property_rent: "Property monthly rent",
            currency: "Currency",
            expenses: "Expenses",
            deposit: "Deposit",
            commissions: "Commissions",
            comm_type: "Type",
            deposit: "Deposit",
            terrace: "Terrace",
            balcony: "Balcony",
            box_garage: "Box - Garage",
            storage: "Storage",
            garden: "Garden",
            elevator: "Elevator",
            disabled_bathroom: "Disabled Bathroom",
            full_furnishing: "Full Furnishing",
            partial_furnishing: "Partial Furnishing",
            kitchen_furnishing: "Kitchen Furnishing",
            communal_garden: "Communal Garden",
            private_garden: "Private Garden",
            swimming_pool: "Swimming Pool",
            sauna: "Sauna",
            disabled_elevator: "Disabled Elevator",
            alarm_system: "Alarm System",
            reception: "Reception",
            external_parking: "External Parking",
            air_conditioning: "Air Conditioning",
            save_btn: "Save property",
            close_btn: "Close",
            full_ownership: "Full ownership",
            bare_ownership: "Bare ownership",
            partial_ownership: "Partial ownership",
            usufruct: "Usufruct",
            timeshare: "Timeshare",
            right_superficies: "Right of superficies",
            pending_certification: "Pending certification",
            exempt_property: "Exempt property",
            not_classifiable: "Not classifiable",
            north: "North",
            south: "South",
            est: "Est",
            west: "West",
            autonomous: "Autonomus",
            centralized: "Centralized",
            absent: "Absent",
            glass_wood: "Glass - Wood",
            double_glass_wood: "Double glass - Wood",
            triple_glass_wood: "Triple glass - Wood",
            glass_metal: "Glass  - Metal",
            double_glass_metal: "Double glass - Metal",
            triple_glass_metal: "Triple glass - Metal",
            glass_pvc: "Glass - PVC",
            double_glass_pvc: "Double glass - PVC",
            triple_glass_pvc: "Triple glass - PVC",

            single_family: "Single-family home",
            multi_family: "Multi-family home",
            apartment: "Apartment",
            condominium: "Condominium",
            townhouse: "Townhouse",
            cooperative: "Cooperative",
            vacation_home: "Vacation home",
            mobile_home: "Mobile home",
            tiny_home: "Tiny home",
            office_building: "Office building",
            retail_property: "Retail property",
            industrial_property: "Industrial property",
            mixed_use: "Mixed-use property",
            hotel: "Hotel",
            vacant_land: "Vacant land",
            agricultural_land: "Agricultural land",
            commercial_land: "Commercial land",
            residential_land: "Residential land",
            warehouse: "Warehouse",
            parking_lot: "Parking lot",
            storage_facility: "Storage facility",
            healthcare_facility: "Healthcare facility",
            educational_facility: "Educational facility",
            religious_facility: "Religious facility",
            deposit_storage_area: "Deposit/storage area",
            garage: "Garage",
            marina: "Marina",
            resort: "Resort",
            ranch: "Ranch",
            room: "Room",

            new: "New",
            like_new: "Like new",
            excellent_condition: "Excellent condition",
            good_condition: "Good condition",
            fair_condition: "Fair condition",
            needs_renovation: "Needs renovation",
            to_renovate: "To renovate",
            under_construction: "Under construction",
            partially_renovated: "Partially renovated",
            unfinished: "Unfinished",
            move_in_ready: "Move-in ready",

            buy_rent: "Buy or rent?",
            sell_rent: "Sell or rent?",
            rent: "Renting",
            selling: "Selling",
            buying: "Buying",

            available_notAvailable: "Available/Not Available",
            available_rent: "Available for rent",
            rented: "Rented",
            available_buy: "Available to buy",
            sold: "Sold"

        },
        contacts: {
            my_contacts: "My contacts",
            no_contacts: "No contacts available...",
            add_contact: "Add contact",
            save_contact: "Save contact",
            update_contact: "Update contact",
            save_contact_changes: "Save changes",
            confirmation_delete: "Delete the selected contact?",
            why_look_prop: "Why are you looking for a property?",
            transfer_date: "When would you like to move in?",
            max_bgt: "Maximum budget",
            min_bgt: "Minimum budget",
            mortage: "Do you need a mortage?",
            sell_home: "Are you going to sell a property?",
            first_house: "Is this your first house?",
            notes: "Notes",

        },
        tenants : {
            tenants: "Tenants",
        },
        profile: {
            profile_title: "Profile",
            access: "Access",
            main_credentials_subtitle: "The main credentials to access your environment",
            pb_information: "Personal & Business information",
            pb_subtitle: "All the information regarding your persona and your business",
            uid_title: "Your user ID",
            uid_subtitle: "Personal user ID code",
            activity_log: "Activity log",
            activity_log_subtitle: "Your recent activity log",
            no_activity: "No recent activity found",
            update_btn: "Update",
        },
        settings: {
            settings_title: "Settings"
        },
        months: {
            january : "January",
            february : "February",
            march : "March",
            april : "April",
            may : "May",
            june : "June",
            july : "July",
            august : "August",
            september : "September",
            october : "October",
            november : "November",
            december : "December",
        }
    },
    it: {
        brand_logo: "FlatJourney AI",
        main_title: "Semplifica la tua attività immobiliare con l'",
        ai: "IA",
        main_description: "Dall'onboarding ai pagamenti, tutto in un unico posto",
        property_management: "Gestione proprietà",
        tenant_oversight: "Supervisione inquilini",
        secure_transactions: "Transazioni sicure",
        instant_messaging: "Messaggi istantanei",
        email_notifications: "Notifiche email",
        calendar_events: "Eventi del calendario",
        ai_driven_analytics: "Analisi AI",
        start_now: "INIZIA ORA",
        login: "Accedi",
        edit: "Modifica",
        delete: "Elimina",
        yes: "Sì",
        no: "No",
        register_form: {
            name: "Nome",
            surname: "Cognome",
            phone: "Telefono",
            repeat_password: "Ripeti password",
            button_register: "Registrati",
            already_registered: "Già registrato?",
            go_login: "Accedi",
            email_format: "Il formato dell'email è errato...",
            password_err: "La password deve essere lunga almeno 10 caratteri e contenere lettere maiuscole, lettere minuscole, caratteri speciali e numeri...",
            password_match: "Le password non corrispondono...",
            red_fields: "Controlla i campi rossi...",
            email_already_present: "Email già presente...",
            errors_back: "È stato rilevato un errore...riprovare",
            problem_back: "C'è un problema con l'accesso...riprovare tra qualche minuto"
        },
        login_form: {
            button_login: "Accedi",
            dont_have_account: "Non hai ancora un account?",
            go_register: "Registrati",
            invalid_email_pass: "Email o password non validi...riprovare"
        },
        onboarding: {
            title: "Gestisci Ogni Fase del Tuo Percorso",
            desc: "Gestisci e controlla senza sforzo ogni aspetto, dall'inizio alla fine.",
            titles: [
                "Onboarding",
                "Validazione IA",
                "Compilazione Documenti",
                "Pagamenti Automatici"
            ],
            descriptions: [
                "L'onboarding ti aiuta a iniziare rapidamente con una presentazione fluida del nostro sistema.",
                "La Validazione IA garantisce che tutti i tuoi dati siano accurati e soddisfino i nostri standard.",
                "Il Riempimento dei Documenti semplifica il processo di completamento e invio della documentazione necessaria.",
                "I Pagamenti Automatici rendono le transazioni facili e senza problemi con la fatturazione automatica."
            ],
            icons: [
                "FaRegUser",
                "FaRobot",
                "FaFileAlt",
                "FaCreditCard"
            ]
        },
        now: "ora",
        loading: "Caricamento...",
        error: "Qualcosa è andato storto...",
        payment_process: {
            title: "Ottimizza e Automatizza i Processi di Pagamento",
            desc: "Assicura transazioni di pagamento fluide e senza intoppi."
        },
        payment_notifications: {
            notification1: "Aisha Thompson ha pagato l'affitto!",
            notification2: "John Doe ha pagato l'affitto!",
            notification3: "Jack Miller ha pagato l'affitto!"
        },
        secure_payments: {
            title: "Pagamenti Sicuri",
            description: "Le transazioni su FlatJourney sono gestite in modo sicuro e affidabile da Stripe, garantendo il massimo della protezione per i tuoi pagamenti."
        },
        manage_calendar: {
            title: "Programma Affitti a Breve e Lungo Termine",
            desc: "Gestisci prenotazioni e programmazioni con facilità.",
            cal_desc_title: "Piattaforma Completa per la Gestione delle Proprietà",
            cal_desc: "Gestisci in modo efficiente sia le prenotazioni a lungo termine che quelle a breve termine da un'unica piattaforma. Che i tuoi inquilini siano in vacanza o risiedano nella tua città, semplifica la programmazione, la gestione e la consegna dei servizi con facilità."
        },
        footer: {
            about_us: "Chi siamo",
            who: "Azienda",
            jobs: "Lavora con noi",
            get_in_touch: "Contatti",
            terms_conditions: "Termini & Condizioni",
            legal_notes: "Note legali",
            brand_rights: "© 2024 FlatJourney AI.",
            address: "Via EG 5, 20145, Milano MI",
            vat: "VAT: 123456789",
        },
        tll: {
            question: "Sei il proprietario dell'immobile o la persona che affitta lo spazio?",
            resp_t: "Sto affittando lo spazio",
            resp_pm: "Sono il proprietario",
        },
        sidebar: {
            home: "Home",
            bookings: "Calendario",
            properties: "Proprietà",
            contacts: "Contatti",
            messages: "Messaggi",
            website_builder: "Website builder",
            transactions: "Transazioni",
            balance: "Saldo",
            report: "Rapporto",
            tenant_analysis: "Analisi inquilini",
            market_pricing: "Prezzi di mercato",
            expenses_forecast: "Previsione spese",
            coming_soon_mex: "In arrivo!",
            payments: "Pagamenti"
        },
        complete_account: {
            title: "Completa la configurazione",
            description: "Completa tutti i passaggi necessari per finalizzare la configurazione",
            completed_steps: "Passaggi completati",
            complete_btn: "Completa",
            not_now_btn: "Non ora",
            personal_info: "Informazioni personali",
            business_info: "Informazioni aziendali",
            deposit_account: "Conto deposito",
            birthday: "Compleanno",
            sex: "Sesso",
            country: "Nazione",
            city: "Città",
            province: "Provincia",
            street: "Strada",
            n: "N.",
            zip: "CAP",
            business_name: "Ragione Sociale",
            vat: "P.IVA",
            stripe_title: "FlatJourneyAI collabora con Stripe per pagamenti senza interruzioni!",
            stripe_desc: "Crea e collega facilmente il tuo account Stripe per iniziare a ricevere pagamenti senza alcuna difficoltà.",
            stripe_button: "Configura account Stripe",
            process_loading: "Caricamento...",
            sepa: "SEPA consente pagamenti automatici, come affitti mensili e ricorrenti, direttamente dal conto bancario. Semplifica i trasferimenti, rendendoli veloci, affidabili e convenienti, garantendo un'esperienza sicura e senza problemi sia per le aziende che per i clienti.",
            card_payment: "Accettare pagamenti con carta di credito e debito garantisce l'accredito dell'affitto veloce e affidabile, con transazioni istantanee e integrazione senza problemi nel tuo sistema di gestione. È ideale per i proprietari che privilegiano velocità e comodità.",
            sepa_fee_1: "1.50€ per pagamenti inferiori a 400€",
            sepa_fee_2: "2.50€ per pagamenti inferiori a 400€",
            card_fee_1: "2,5% + 0,25€ per le carte standard EU",
            card_fee_2: "2,9% + 0,25€ per le carte premium EU"
        },
        popup: {
            success: "Salvato con successo",
            error: "Si è verificato un errore"
        },
        booking: {
            date_leng: "it-IT",
            no_bookings: "Nessuna appuntamento disponibile...",
            appointments: "Appuntamenti",
            date_order: "La data di inizio non può essere maggiore della data di fine...",
            add_booking: "Aggiungi appuntamento",
            add_title: "Aggiungi titolo...",
            booking_type: "Tipo di appuntamento",
            date_time: "Data e ora",
            all_day: "Tutto il giorno",
            partecipants: "Partecipanti",
            location: "Luogo",
            description: "Descrizione",
            add_description: "Aggiungi una breve descrizione...",
            short_term: "Breve Termine",
            long_term: "Lungo Termine",
            personal: "Personale",
            meeting: "Riunione",
            visit: "Visita",
            second_visit: "Seconda visita",
            contract_sign: "Firma del contratto",
            work: "Lavoro",
            other: "Altro",
            save_btn: "Salva prenotazione",
            close_btn: "Chiudi",
        },
        properties: {
            my_properties: "Le mie proprietà",
            no_property: "Nessuna proprietà disponibile...",
            add_property: "Aggiungi proprietà",
            apply_filters: "Applica filtri",
            filter_btn: "Filtra",
            title_main: "Titolo",
            title_prop: "Aggiungi un nome",
            unit_type: "Tipo di unità",
            nation: "Nazione",
            city: "Città",
            street_num: "Via e numero",
            zip: "CAP",
            prop_type: "Tipo di proprietà",
            mt2: "Metri quadrati - mt2",
            floor: "Piano",
            building_floors: "Piani totali dell'edificio",
            energy_class: "Classe energetica",
            exposure: "Esposizione",
            heating: "Riscaldamento",
            external_fixtures: "Infissi esterni",
            prop_state: "Stato della proprietà",
            construction_year: "Anno di costruzione",
            living: "Spazio abitativo",
            bedroom: "Camera da letto",
            kitchen: "Cucina",
            bathroom: "Bagno",
            other_included_details: "Altri dettagli inclusi",
            price: "Prezzo della proprietà",
            property_rent: "Affitto mensile della proprietà",
            currency: "Valuta",
            expenses: "Spese",
            deposit: "Deposito",
            commissions: "Provvigioni terze parti",
            comm_type: "Tipo",
            deposit: "Deposito",
            terrace: "Terrazza",
            balcony: "Balcone",
            box_garage: "Box - Garage",
            storage: "Deposito",
            garden: "Giardino",
            elevator: "Ascensore",
            disabled_bathroom: "Bagno per disabili",
            full_furnishing: "Arredamento completo",
            partial_furnishing: "Arredamento parziale",
            kitchen_furnishing: "Arredamento cucina",
            communal_garden: "Giardino comune",
            private_garden: "Giardino privato",
            swimming_pool: "Piscina",
            sauna: "Sauna",
            disabled_elevator: "Ascensore per disabili",
            alarm_system: "Sistema di allarme",
            reception: "Reception",
            external_parking: "Parcheggio esterno",
            air_conditioning: "Aria condizionata",
            save_btn: "Salva proprietà",
            close_btn: "Chiudi",
            full_ownership: "Intera proprietà",
            bare_ownership: "Nuda proprietà",
            partial_ownership: "Parziale proprietà",
            usufruct: "Usufrutto",
            timeshare: "Multiproprietà",
            right_superficies: "Diritto di superficie",
            pending_certification: "In attesa di certificazione",
            exempt_property: "Proprietà esente",
            not_classifiable: "Non classificabile",
            north: "Nord",
            south: "Sud",
            est: "Est",
            west: "Ovest",
            autonomous: "Autonomo",
            centralized: "Centralizzato",
            absent: "Assente",
            glass_wood: "Vetro - Legno",
            double_glass_wood: "Doppio vetro - Legno",
            triple_glass_wood: "Triplo vetro - Legno",
            glass_metal: "Vetro - Metallo",
            double_glass_metal: "Doppio vetro - Metallo",
            triple_glass_metal: "Triplo vetro - Metallo",
            glass_pvc: "Vetro - PVC",
            double_glass_pvc: "Doppio vetro - PVC",
            triple_glass_pvc: "Triplo vetro - PVC",

            single_family: "Casa unifamiliare",
            multi_family: "Casa plurifamiliare",
            apartment: "Appartamento",
            condominium: "Condominio",
            townhouse: "Villetta a schiera",
            cooperative: "Cooperativa",
            vacation_home: "Casa vacanza",
            mobile_home: "Casa mobile",
            tiny_home: "Mini casa",
            office_building: "Edificio per uffici",
            retail_property: "Proprietà commerciale",
            industrial_property: "Proprietà industriale",
            mixed_use: "Proprietà a uso misto",
            hotel: "Hotel",
            vacant_land: "Terreno non edificato",
            agricultural_land: "Terreno agricolo",
            commercial_land: "Terreno commerciale",
            residential_land: "Terreno residenziale",
            warehouse: "Magazzino",
            parking_lot: "Parcheggio",
            storage_facility: "Deposito",
            healthcare_facility: "Struttura sanitaria",
            educational_facility: "Struttura educativa",
            religious_facility: "Struttura religiosa",
            deposit_storage_area: "Area deposito",
            garage: "Garage",
            marina: "Marina",
            resort: "Resort",
            ranch: "Ranch",
            room: "Stanza",

            new: "Nuovo",
            like_new: "Come nuovo",
            excellent_condition: "In ottime condizioni",
            good_condition: "In buone condizioni",
            fair_condition: "In condizioni discrete",
            needs_renovation: "Da ristrutturare",
            to_renovate: "Da rinnovare",
            under_construction: "In costruzione",
            partially_renovated: "Parzialmente ristrutturato",
            unfinished: "Non finito",
            move_in_ready: "Pronto per essere abitato",
            buy_rent: "Comprare o affittare?",
            sell_rent: "Vendere o affittare?",
            rent: "Affittare",
            selling: "Vendere",
            buying: "Comprare",
            available_notAvailable: "Disponibile/Non disponibile",
            available_rent: "Disponibile per affitto",
            rented: "Affittato",
            available_buy: "Disponibile per l'acquisto",
            sold: "Venduto",


        },
        contacts: {
            my_contacts: "I miei contatti",
            no_contacts: "Nessun contatto disponibile...",
            add_contact: "Aggiungi contatto",
            save_contact: "Salva contatto",
            update_contact: "Aggiorna contatto",
            save_contact_changes: "Salva modifiche",
            confirmation_delete: "Eliminare il contatto selezionato?",
            why_look_prop: "Perché stai cercando una proprietà?",
            transfer_date: "Quando vorresti trasferirti?",
            max_bgt: "Budget massimo",
            min_bgt: "Budget minimo",
            mortage: "Hai bisogno di un mutuo?",
            sell_home: "Stai vendendo una proprietà?",
            first_house: "È la tua prima casa?",
            notes: "Note",
        },
        tenants : {
            tenants: "Inquilini",
        },
        profile: {
            profile_title: "Profilo",
            access: "Accesso",
            main_credentials_subtitle: "Le credenziali principali per accedere al tuo ambiente",
            pb_information: "Informazioni personali e aziendali",
            pb_subtitle: "Tutte le informazioni riguardanti la tua persona e la tua attività",
            uid_title: "Il tuo ID utente",
            uid_subtitle: "Codice ID personale",
            activity_log: "Registro attività",
            activity_log_subtitle: "Il tuo registro delle attività recenti",
            no_activity: "Nessuna attività recente trovata",
            update_btn: "Aggiorna"
        },
        settings: {
            settings_title: "Impostazioni"
        },  
        months: {
            january: "Gennaio",
            february: "Febbraio",
            march: "Marzo",
            april: "Aprile",
            may: "Maggio",
            june: "Giugno",
            july: "Luglio",
            august: "Agosto",
            september: "Settembre",
            october: "Ottobre",
            november: "Novembre",
            december: "Dicembre",
        }                 
    },
    // fr: {
    //     brand_logo: "FlatJourney AI",
    //     main_title: "Simplifiez votre activité immobilière avec l'",
    //     ai: "IA",
    //     main_description: "De l'intégration aux paiements, tout en un seul endroit",
    //     property_management: "Gestion des Propriétés",
    //     tenant_oversight: "Surveillance des Locataires",
    //     secure_transactions: "Transactions Sécurisées",
    //     instant_messaging: "Messagerie Instantanée",
    //     email_notifications: "Notifications par Email",
    //     calendar_events: "Événements du Calendrier",
    //     ai_driven_analytics: "Analytique Pilotée par l'IA",
    //     start_now: "DÉMARREZ MAINTENANT",
    //     login: "Connexion",
    //     edit: "Modifier",
    //     delete: "Supprimer",
    //     yes: "Oui",
    //     no: "Non",
    //     register_form: {
    //         name: "Prénom",
    //         surname: "Nom de famille",
    //         phone: "Téléphone",
    //         repeat_password: "Répétez le mot de passe",
    //         button_register: "S'inscrire",
    //         already_registered: "Déjà inscrit ?",
    //         go_login: "Connexion",
    //         email_format: "Le format de l'email est incorrect...",
    //         password_err: "Le mot de passe doit comporter au moins 10 caractères et contenir des lettres majuscules, des lettres minuscules, des caractères spéciaux et des chiffres...",
    //         password_match: "Les mots de passe ne correspondent pas...",
    //         red_fields: "Vérifiez les champs rouges...",
    //         email_already_present: "Email déjà présente...",
    //         errors_back: "Une erreur a été détectée...réessayez",
    //         problem_back: "Il y a un problème avec l'accès...réessayez dans quelques minutes"
    //     },
    //     login_form: {
    //         button_login: "Connexion",
    //         dont_have_account: "Vous n'avez pas de compte ?",
    //         go_register: "S'inscrire",
    //         invalid_email_pass: "Email ou mot de passe invalide...réessayez"
    //     },
    //     onboarding: {
    //         title: "Maîtrisez Chaque Étape de Votre Parcours",
    //         desc: "Gérez et surveillez chaque aspect sans effort, du début à la fin.",
    //         titles: [
    //             "Intégration",
    //             "Validation IA",
    //             "Remplissage de Documents",
    //             "Paiements Automatiques"
    //         ],
    //         descriptions: [
    //             "L'intégration vous aide à commencer rapidement avec une présentation fluide de notre système.",
    //             "La Validation IA garantit que toutes vos données sont précises et répondent à nos normes.",
    //             "Le Remplissage de Documents simplifie le processus de complétion et de soumission des documents nécessaires.",
    //             "Les Paiements Automatiques facilitent les transactions avec une facturation automatique sans tracas."
    //         ],
    //         icons: [
    //             "FaRegUser",
    //             "FaRobot",
    //             "FaFileAlt",
    //             "FaCreditCard"
    //         ]
    //     },
    //     now: "maintenant",
    //     loading: "Chargement...",
    //     error: "Quelque chose s'est mal passé...",
    //     payment_process: {
    //         title: "Optimisez et Automatisez les Processus de Paiement",
    //         desc: "Assurez des transactions de paiement fluides et sans accroc."
    //     },
    //     payment_notifications: {
    //         notification1: "Aisha Thompson a payé son loyer !",
    //         notification2: "John Doe a payé son loyer !",
    //         notification3: "Jack Miller a payé son loyer !"
    //     },
    //     secure_payments: {
    //         title: "Paiements Sécurisés",
    //         description: "Les transactions sur FlatJourney sont gérées de manière sécurisée et fiable par Stripe, garantissant une protection maximale pour vos paiements."
    //     },
    //     manage_calendar: {
    //         title: "Gérez les Locations à Court et Long Terme",
    //         desc: "Gérez facilement les réservations et la planification.",
    //         cal_desc_title: "Plateforme de Gestion Immobilière Complète",
    //         cal_desc: "Gérez efficacement les réservations à long terme et à court terme depuis une seule plateforme. Que vos locataires soient en vacances ou résident dans votre ville, simplifiez la planification, la gestion et la livraison des services avec aisance."
    //     },
    //     footer: {
    //         about_us: "À propos de nous",
    //         who: "Entreprise",
    //         jobs: "Travailler avec nous",
    //         get_in_touch: "Contacts",
    //         terms_conditions: "Termes et Conditions",
    //         legal_notes: "Mentions légales",
    //         brand_rights: "© 2024 FlatJourney AI.",
    //         address: "Via EG 5, 20145, Milan MI",
    //         vat: "TVA: 123456789"
    //     },
    //     tll: {
    //         question: "Êtes-vous le propriétaire ou la personne qui loue l'espace?",
    //         resp_t: "Je loue l'espace",
    //         resp_pm: "Je suis le propriétaire",
    //     },
    //     sidebar: {
    //         home: "Accueil",
    //         bookings: "Calendrier",
    //         properties: "Propriétés",
    //         contacts: "Contacts",
    //         messages: "Messages",
    //         website_builder: "Website builder",
    //         transactions: "Transactions",
    //         balance: "Solde",
    //         report: "Rapport",
    //         tenant_analysis: "Analyse des locataires",
    //         market_pricing: "Tarification du marché",
    //         expenses_forecast: "Prévisions des dépenses",
    //         coming_soon_mex: "À venir!",
    //     },
    //     complete_account: {
    //         title: "Finaliser la configuration",
    //         description: "Complétez toutes les étapes nécessaires pour finaliser la configuration",
    //         completed_steps: "Étapes terminées",
    //         complete_btn: "Terminer",
    //         not_now_btn: "Le faire plus tard",
    //         personal_info: "Informations personnelles",
    //         business_info: "Informations professionnelles",
    //         deposit_account: "Compte de dépôt",
    //         birthday: "Anniversaire",
    //         sex: "Sexe",
    //         country: "Pays",
    //         city: "Ville",
    //         province: "Province",
    //         street: "Rue",
    //         n: "N°",
    //         zip: "Code postal",
    //         business_name: "Raison sociale",
    //         vat: "TVA",
    //         stripe_title: "FlatJourneyAI s'associe à Stripe pour des paiements sans effort !",
    //         stripe_desc: "Créez et connectez facilement votre compte Stripe pour commencer à recevoir des paiements sans aucune difficulté.",
    //         stripe_button: "Configurer le Compte Stripe",
    //         process_loading: "Chargement..."
    //     },
    //     popup: {
    //         success: "Enregistré avec succès",
    //         error: "Une erreur est survenue"
    //     },
    //     booking: {
    //         date_leng: "fr-FR",
    //         no_bookings: "Aucune réservation disponible...",
    //         appointments: "Rendez-vous",
    //         date_order: "La date de début ne peut pas être supérieure à la date de fin...",
    //         add_booking: "Ajouter une réservation",
    //         add_title: "Ajouter un titre...",
    //         booking_type: "Type de réservation",
    //         date_time: "Date et heure",
    //         all_day: "Toute la journée",
    //         partecipants: "Participants",
    //         location: "Lieu",
    //         description: "Description",
    //         add_description: "Ajouter une brève description...",
    //         short_term: "Court Terme",
    //         long_term: "Long Terme",
    //         personal: "Personnel",
    //         meeting: "Réunion",
    //         visit: "Visite",
    //         second_visit: "Deuxième visite",
    //         contract_sign: "Signature du contrat",
    //         work: "Travail",
    //         save_btn: "Enregistrer la réservation",
    //         close_btn: "Fermer",
    //     },
    //     properties: {
    //         my_properties: "Mes propriétés",
    //         no_property: "Aucune propriété disponible...",
    //         add_property: "Ajouter une propriété",
    //         apply_filters: "Appliquer les filtres",
    //         filter_btn: "Filtrer",
    //         title_main: "Titre",
    //         title_prop: "Ajouter un nom",
    //         unit_type: "Type d'unité",
    //         nation: "Nation",
    //         city: "Ville",
    //         street_num: "Rue et numéro",
    //         zip: "Code postal",
    //         prop_type: "Type de propriété",
    //         mt2: "Mètres carrés - m2",
    //         floor: "Étage",
    //         building_floors: "Nombre total d'étages",
    //         energy_class: "Classe énergétique",
    //         exposure: "Exposition",
    //         heating: "Chauffage",
    //         external_fixtures: "Menuiserie extérieure",
    //         prop_state: "État de la propriété",
    //         construction_year: "Année de construction",
    //         living: "Espace de vie",
    //         bedroom: "Chambre",
    //         kitchen: "Cuisine",
    //         bathroom: "Salle de bain",
    //         other_included_details: "Autres détails inclus",
    //         price: "Prix de propriété",
    //         property_rent: "Loyer mensuel de la propriété",
    //         currency: "Devise",
    //         expenses: "Dépenses",
    //         deposit: "Dépôt",
    //         commissions: "Commissions",
    //         comm_type: "Type",
    //         deposit: "Dépôt",
    //         terrace: "Terrasse",
    //         balcony: "Balcon",
    //         box_garage: "Box - Garage",
    //         storage: "Stockage",
    //         garden: "Jardin",
    //         elevator: "Ascenseur",
    //         disabled_bathroom: "Salle de bain pour handicapés",
    //         full_furnishing: "Mobilier complet",
    //         partial_furnishing: "Mobilier partiel",
    //         kitchen_furnishing: "Cuisine équipée",
    //         communal_garden: "Jardin commun",
    //         private_garden: "Jardin privé",
    //         swimming_pool: "Piscine",
    //         sauna: "Sauna",
    //         disabled_elevator: "Ascenseur pour handicapés",
    //         alarm_system: "Système d'alarme",
    //         reception: "Réception",
    //         external_parking: "Parking extérieur",
    //         air_conditioning: "Climatisation",
    //         save_btn: "Enregistrer la propriété",
    //         close_btn: "Fermer",
    //         full_ownership: "Pleine propriété",
    //         bare_ownership: "Nue-propriété",
    //         partial_ownership: "Propriété partielle",
    //         usufruct: "Usufruit",
    //         timeshare: "Multipropriété",
    //         right_superficies: "Droit de superficie",
    //         pending_certification: "En attente de certification",
    //         exempt_property: "Propriété exemptée",
    //         not_classifiable: "Non classifiable",
    //         north: "Nord",
    //         south: "Sud",
    //         est: "Est",
    //         west: "Ouest",
    //         autonomous: "Autonome",
    //         centralized: "Centralisé",
    //         absent: "Absent",
    //         glass_wood: "Verre - Bois",
    //         double_glass_wood: "Double vitrage - Bois",
    //         triple_glass_wood: "Triple vitrage - Bois",
    //         glass_metal: "Verre - Métal",
    //         double_glass_metal: "Double vitrage - Métal",
    //         triple_glass_metal: "Triple vitrage - Métal",
    //         glass_pvc: "Verre - PVC",
    //         double_glass_pvc: "Double vitrage - PVC",
    //         triple_glass_pvc: "Triple vitrage - PVC",

    //         single_family: "Maison individuelle",
    //         multi_family: "Maison multifamiliale",
    //         apartment: "Appartement",
    //         condominium: "Condominium",
    //         townhouse: "Maison de ville",
    //         cooperative: "Coopérative",
    //         vacation_home: "Maison de vacances",
    //         mobile_home: "Maison mobile",
    //         tiny_home: "Petite maison",
    //         office_building: "Immeuble de bureaux",
    //         retail_property: "Propriété commerciale",
    //         industrial_property: "Propriété industrielle",
    //         mixed_use: "Propriété à usage mixte",
    //         hotel: "Hôtel",
    //         vacant_land: "Terrain non bâti",
    //         agricultural_land: "Terrain agricole",
    //         commercial_land: "Terrain commercial",
    //         residential_land: "Terrain résidentiel",
    //         warehouse: "Entrepôt",
    //         parking_lot: "Parking",
    //         storage_facility: "Installation de stockage",
    //         healthcare_facility: "Établissement de santé",
    //         educational_facility: "Établissement éducatif",
    //         religious_facility: "Établissement religieux",
    //         deposit_storage_area: "Zone de dépôt",
    //         garage: "Garage",
    //         marina: "Marina",
    //         resort: "Complexe hôtelier",
    //         ranch: "Ranch",
    //         room: "Chambre",

    //         new: "Neuf",
    //         like_new: "Comme neuf",
    //         excellent_condition: "En excellent état",
    //         good_condition: "En bon état",
    //         fair_condition: "En état correct",
    //         needs_renovation: "À rénover",
    //         to_renovate: "À rénover",
    //         under_construction: "En construction",
    //         partially_renovated: "Partiellement rénové",
    //         unfinished: "Non terminé",
    //         move_in_ready: "Prêt à emménager",

    //         buy_rent: "Acheter ou louer?",
    //         sell_rent: "Vendre ou louer?",
    //         rent: "Louer",
    //         selling: "Vendre",
    //         buying: "Acheter",
    //         available_notAvailable: "Disponible/Non disponible",
    //         available_rent: "Disponible à louer",
    //         rented: "Loué",
    //         available_buy: "Disponible à acheter",
    //         sold: "Vendu",


    //     },
    //     contacts: {
    //         my_contacts: "Mes contacts",
    //         no_contacts: "Aucun contact disponible...",
    //         add_contact: "Ajouter un contact",
    //         save_contact: "Enregistrer le contact",
    //         update_contact: "Mettre à jour le contact",
    //         save_contact_changes: "Enregistrer les modifications",
    //         confirmation_delete: "Supprimer le contact sélectionné ?",
    //         why_look_prop: "Pourquoi cherchez-vous une propriété ?",
    //         transfer_date: "Quand souhaitez-vous emménager ?",
    //         max_bgt: "Budget maximum",
    //         min_bgt: "Budget minimum",
    //         mortage: "Avez-vous besoin d'un prêt hypothécaire ?",
    //         sell_home: "Allez-vous vendre une propriété ?",
    //         first_house: "Est-ce votre première maison ?",
    //         notes: "Remarques",
    //     },
    //     profile: {
    //         profile_title: "Profil",
    //         access: "Accès",
    //         main_credentials_subtitle: "Les principales informations d'identification pour accéder à votre environnement",
    //         pb_information: "Informations personnelles et professionnelles",
    //         pb_subtitle: "Toutes les informations concernant votre personne et votre entreprise",
    //         uid_title: "Votre identifiant utilisateur",
    //         uid_subtitle: "Code d'identification personnel",
    //         activity_log: "Journal d'activité",
    //         activity_log_subtitle: "Votre journal d'activité récent",
    //         no_activity: "Aucune activité récente trouvée",
    //         update_btn: "Mettre à jour"
    //     },
    //     settings: {
    //         settings_title: "Paramètres"
    //     },             
    // },
    // de: {
    //     brand_logo: "FlatJourney AI",
    //     main_title: "Optimieren Sie Ihr Immobiliengeschäft mit",
    //     ai: " AI",
    //     main_description: "Von der Einarbeitung bis zu Zahlungen, alles an einem Ort",
    //     property_management: "Immobilienverwaltung",
    //     tenant_oversight: "Mieterüberwachung",
    //     secure_transactions: "Sichere Transaktionen",
    //     instant_messaging: "Sofortnachrichten",
    //     email_notifications: "Mail Benachrichtigungen",
    //     calendar_events: "Kalenderereignisse",
    //     ai_driven_analytics: "AI-gesteuerte Analysen",
    //     start_now: "JETZT STARTEN",
    //     login: "Anmelden",
    //     edit: "Bearbeiten",
    //     delete: "Löschen",
    //     yes: "Ja",
    //     no: "Nein",
    //     register_form: {
    //         name: "Name",
    //         surname: "Nachname",
    //         phone: "Telefon",
    //         repeat_password: "Passwort wiederholen",
    //         button_register: "Registrieren",
    //         already_registered: "Bereits registriert?",
    //         go_login: "Anmelden",
    //         email_format: "Das Format der E-Mail ist falsch...",
    //         password_err: "Das Passwort muss mindestens 10 Zeichen lang sein und Großbuchstaben, Kleinbuchstaben, Sonderzeichen und Zahlen enthalten...",
    //         password_match: "Die Passwörter stimmen nicht überein...",
    //         red_fields: "Überprüfen Sie die roten Felder...",
    //         email_already_present: "E-Mail bereits vorhanden...",
    //         errors_back: "Ein Fehler wurde festgestellt...bitte erneut versuchen",
    //         problem_back: "Es gibt ein Problem mit dem Zugriff...bitte in wenigen Minuten erneut versuchen"
    //     },
    //     login_form: {
    //         button_login: "Anmelden",
    //         dont_have_account: "Noch kein Konto?",
    //         go_register: "Registrieren",
    //         invalid_email_pass: "Ungültige E-Mail oder Passwort...bitte erneut versuchen"
    //     },
    //     onboarding: {
    //         title: "Meistern Sie Jede Phase Ihrer Reise",
    //         desc: "Verwalten und überwachen Sie mühelos jeden Aspekt von Anfang bis Ende.",
    //         titles: [
    //             "Einarbeitung",
    //             "KI-Validierung",
    //             "Dokumentenfüllung",
    //             "Automatische Zahlungen"
    //         ],
    //         descriptions: [
    //             "Die Einarbeitung hilft Ihnen, schnell mit einer reibungslosen Einführung in unser System zu beginnen.",
    //             "Die KI-Validierung stellt sicher, dass alle Ihre Daten genau sind und unseren Standards entsprechen.",
    //             "Die Dokumentenfüllung vereinfacht den Prozess des Ausfüllens und Einreichens erforderlicher Unterlagen.",
    //             "Automatische Zahlungen machen Transaktionen einfach und stressfrei mit automatischer Abrechnung."
    //         ],
    //         icons: [
    //             "FaRegUser",
    //             "FaRobot",
    //             "FaFileAlt",
    //             "FaCreditCard"
    //         ]
    //     },
    //     now: "jetzt",
    //     loading: "Laden...",
    //     error: "Etwas ist schief gelaufen...",
    //     payment_process: {
    //         title: "Zahlungsprozesse Optimieren und Automatisieren",
    //         desc: "Stellen Sie reibungslose und problemfreie Zahlungstransaktionen sicher."
    //     },
    //     payment_notifications: {
    //         notification1: "Aisha Thompson hat ihre Miete bezahlt!",
    //         notification2: "John Doe hat seine Miete bezahlt!",
    //         notification3: "Jack Miller hat seine Miete bezahlt!"
    //     },
    //     secure_payments: {
    //         title: "Sichere Zahlungen",
    //         description: "Die Transaktionen auf FlatJourney werden sicher und zuverlässig von Stripe verarbeitet, um maximalen Schutz für Ihre Zahlungen zu gewährleisten."
    //     },
    //     manage_calendar: {
    //         title: "Verwalten Sie Kurz- und Langzeitmieten",
    //         desc: "Verwalten Sie Buchungen und Zeitpläne mühelos.",
    //         cal_desc_title: "Umfassende Plattform zur Immobilienverwaltung",
    //         cal_desc: "Verwalten Sie effizient sowohl Langzeit- als auch Kurzzeitbuchungen über eine einzige Plattform. Egal, ob Ihre Mieter im Urlaub sind oder in Ihrer Stadt wohnen, vereinfachen Sie die Planung, Verwaltung und Servicebereitstellung mühelos."
    //     },
    //     footer: {
    //         about_us: "Über uns",
    //         who: "Unternehmen",
    //         jobs: "Arbeiten bei uns",
    //         get_in_touch: "Kontakt",
    //         terms_conditions: "Allgemeine Geschäftsbedingungen",
    //         legal_notes: "Rechtliche Hinweise",
    //         brand_rights: "© 2024 FlatJourney AI.",
    //         address: "Via EG 5, 20145, Mailand MI",
    //         vat: "USt-IdNr: 123456789"
    //     },
    //     tll: {
    //         question: "Sind Sie der Eigentümer der Immobilie oder die Person, die den Raum mietet?",
    //         resp_t: "Ich miete den Raum",
    //         resp_pm: "Ich bin der Eigentümer",
    //     },
    //     sidebar: {
    //         home: "Startseite",
    //         bookings: "Kalender",
    //         properties: "Immobilien",
    //         contacts: "Kontakte",
    //         messages: "Nachrichten",
    //         website_builder: "Website-Ersteller",
    //         transactions: "Transaktionen",
    //         balance: "Kontostand",
    //         report: "Bericht",
    //         tenant_analysis: "Mieteranalyse",
    //         market_pricing: "Marktpreisgestaltung",
    //         expenses_forecast: "Ausgabenprognose",
    //         coming_soon_mex: "Bald verfügbar!",
    //     },
    //     complete_account: {
    //         title: "Konfiguration abschließen",
    //         description: "Schließen Sie alle erforderlichen Schritte ab, um die Konfiguration zu beenden",
    //         completed_steps: "Abgeschlossene Schritte",
    //         complete_btn: "Abschließen",
    //         not_now_btn: "Später erledigen",
    //         personal_info: "Persönliche Informationen",
    //         business_info: "Geschäftsinformationen",
    //         deposit_account: "Einzahlungskonto",
    //         birthday: "Geburtstag",
    //         sex: "Geschlecht",
    //         country: "Land",
    //         city: "Stadt",
    //         province: "Provinz",
    //         street: "Straße",
    //         n: "Nr.",
    //         zip: "PLZ",
    //         business_name: "Firmenname",
    //         vat: "Umsatzsteuer-ID",
    //         stripe_title: "FlatJourneyAI kooperiert mit Stripe für nahtlose Zahlungen!",
    //         stripe_desc: "Erstellen und verbinden Sie Ihr Stripe-Konto mühelos, um sofort Zahlungen zu erhalten.",
    //         stripe_button: "Stripe-Konto Einrichten",
    //         process_loading: "Lädt..."
    //     },
    //     popup: {
    //         success: "Erfolgreich gespeichert",
    //         error: "Ein Fehler ist aufgetreten"
    //     },
    //     booking: {
    //         date_leng: "de-DE",
    //         no_bookings: "Keine Buchungen verfügbar...",
    //         appointments: "Termine",
    //         date_order: "Das Startdatum darf nicht später als das Enddatum sein...",
    //         add_booking: "Buchung hinzufügen",
    //         add_title: "Titel hinzufügen...",
    //         booking_type: "Buchungstyp",
    //         date_time: "Datum und Uhrzeit",
    //         all_day: "Ganztägig",
    //         partecipants: "Teilnehmer",
    //         location: "Ort",
    //         description: "Beschreibung",
    //         add_description: "Fügen Sie eine kurze Beschreibung hinzu...",
    //         short_term: "Kurzfristig",
    //         long_term: "Langfristig",
    //         personal: "Persönlich",
    //         meeting: "Besprechung",
    //         visit: "Besuch",
    //         second_visit: "Zweiter Besuch",
    //         contract_sign: "Vertragsunterzeichnung",
    //         work: "Arbeit",
    //         save_btn: "Buchung speichern",
    //         close_btn: "Schließen",
    //     },
    //     properties: {
    //         my_properties: "Meine Eigenschaften",
    //         no_property: "Keine Immobilien verfügbar...",
    //         add_property: "Immobilie hinzufügen",
    //         apply_filters: "Filter anwenden",
    //         filter_btn: "Filtern",
    //         title_main: "Titel",
    //         title_prop: "Name hinzufügen",
    //         unit_type: "Einheitstyp",
    //         nation: "Nation",
    //         city: "Stadt",
    //         street_num: "Straße und Nummer",
    //         zip: "Postleitzahl",
    //         prop_type: "Immobilienart",
    //         mt2: "Quadratmeter - m2",
    //         floor: "Stockwerk",
    //         building_floors: "Gesamtzahl der Stockwerke",
    //         energy_class: "Energieklasse",
    //         exposure: "Ausrichtung",
    //         heating: "Heizung",
    //         external_fixtures: "Außenbeschläge",
    //         prop_state: "Zustand der Immobilie",
    //         construction_year: "Baujahr",
    //         living: "Wohnfläche",
    //         bedroom: "Schlafzimmer",
    //         kitchen: "Küche",
    //         bathroom: "Badezimmer",
    //         other_included_details: "Weitere enthaltene Details",
    //         price: "Immobilien preis",
    //         property_rent: "Monatliche Miete der Immobilie",
    //         currency: "Währung",
    //         expenses: "Nebenkosten",
    //         deposit: "Kaution",
    //         commissions: "Provisionen",
    //         comm_type: "Typ",
    //         deposit: "Kaution",
    //         terrace: "Terrasse",
    //         balcony: "Balkon",
    //         box_garage: "Box - Garage",
    //         storage: "Lagerung",
    //         garden: "Garten",
    //         elevator: "Aufzug",
    //         disabled_bathroom: "Behindertengerechtes Badezimmer",
    //         full_furnishing: "Vollmöblierung",
    //         partial_furnishing: "Teilweise Möblierung",
    //         kitchen_furnishing: "Küchenausstattung",
    //         communal_garden: "Gemeinschaftsgarten",
    //         private_garden: "Privater Garten",
    //         swimming_pool: "Schwimmbad",
    //         sauna: "Sauna",
    //         disabled_elevator: "Behindertengerechter Aufzug",
    //         alarm_system: "Alarmsystem",
    //         reception: "Empfang",
    //         external_parking: "Außenparkplatz",
    //         air_conditioning: "Klimaanlage",
    //         save_btn: "Eigenschaft speichern",
    //         close_btn: "Schließen",
    //         full_ownership: "Volleigentum",
    //         bare_ownership: "Nutznießungseigentum",
    //         partial_ownership: "Teileigentum",
    //         usufruct: "Nießbrauch",
    //         timeshare: "Zeiteigentum",
    //         right_superficies: "Oberflächennutzungsrecht",
    //         pending_certification: "Ausstehende Zertifizierung",
    //         exempt_property: "Befreites Eigentum",
    //         not_classifiable: "Nicht klassifizierbar",
    //         north: "Nord",
    //         south: "Süd",
    //         est: "Ost",
    //         west: "West",
    //         autonomous: "Autonom",
    //         centralized: "Zentralisiert",
    //         absent: "Abwesend",
    //         glass_wood: "Glas - Holz",
    //         double_glass_wood: "Doppelverglasung - Holz",
    //         triple_glass_wood: "Dreifachverglasung - Holz",
    //         glass_metal: "Glas - Metall",
    //         double_glass_metal: "Doppelverglasung - Metall",
    //         triple_glass_metal: "Dreifachverglasung - Metall",
    //         glass_pvc: "Glas - PVC",
    //         double_glass_pvc: "Doppelverglasung - PVC",
    //         triple_glass_pvc: "Dreifachverglasung - PVC",

    //         single_family: "Einfamilienhaus",
    //         multi_family: "Mehrfamilienhaus",
    //         apartment: "Wohnung",
    //         condominium: "Eigentumswohnung",
    //         townhouse: "Reihenhaus",
    //         cooperative: "Genossenschaft",
    //         vacation_home: "Ferienhaus",
    //         mobile_home: "Mobilheim",
    //         tiny_home: "Kleines Haus",
    //         office_building: "Bürogebäude",
    //         retail_property: "Einzelhandelsimmobilie",
    //         industrial_property: "Industrieimmobilie",
    //         mixed_use: "Mischgenutzte Immobilie",
    //         hotel: "Hotel",
    //         vacant_land: "Unbebautes Land",
    //         agricultural_land: "Landwirtschaftliche Fläche",
    //         commercial_land: "Gewerbegrundstück",
    //         residential_land: "Wohngebiet",
    //         warehouse: "Lagerhaus",
    //         parking_lot: "Parkplatz",
    //         storage_facility: "Lagerraum",
    //         healthcare_facility: "Gesundheitseinrichtung",
    //         educational_facility: "Bildungseinrichtung",
    //         religious_facility: "Religiöse Einrichtung",
    //         deposit_storage_area: "Lagerfläche",
    //         garage: "Garage",
    //         marina: "Jachthafen",
    //         resort: "Ferienort",
    //         ranch: "Ranch",
    //         room: "Zimmer",

    //         new: "Neu",
    //         like_new: "Wie neu",
    //         excellent_condition: "In ausgezeichnetem Zustand",
    //         good_condition: "In gutem Zustand",
    //         fair_condition: "In akzeptablem Zustand",
    //         needs_renovation: "Renovierungsbedürftig",
    //         to_renovate: "Zu renovieren",
    //         under_construction: "Im Bau",
    //         partially_renovated: "Teilweise renoviert",
    //         unfinished: "Unvollendet",
    //         move_in_ready: "Bezugsfertig",

    //         buy_rent: "Kaufen oder mieten?",
    //         sell_rent: "Verkaufen oder mieten?",
    //         rent: "Mieten",
    //         selling: "Verkaufen",
    //         buying: "Kaufen",

    //         available_notAvailable: "Verfügbar/Nicht verfügbar",
    //         available_rent: "Verfügbar zur Miete",
    //         rented: "Vermietet",
    //         available_buy: "Verfügbar zum Kauf",
    //         sold: "Verkauft",

    //     },
    //     contacts: {
    //         my_contacts: "Meine Kontakte",
    //         no_contacts: "Keine Kontakte verfügbar...",
    //         add_contact: "Kontakt hinzufügen",
    //         save_contact: "Kontakt speichern",
    //         update_contact: "Kontakt aktualisieren",
    //         save_contact_changes: "Änderungen speichern",
    //         confirmation_delete: "Den ausgewählten Kontakt löschen?",
    //         why_look_prop: "Warum suchen Sie eine Immobilie?",
    //         transfer_date: "Wann möchten Sie einziehen?",
    //         max_bgt: "Maximales Budget",
    //         min_bgt: "Minimales Budget",
    //         mortage: "Benötigen Sie eine Hypothek?",
    //         sell_home: "Verkaufen Sie eine Immobilie?",
    //         first_house: "Ist das Ihr erstes Haus?",
    //         notes: "Notizen",
    //     },
    //     profile: {
    //         profile_title: "Profil",
    //         access: "Zugang",
    //         main_credentials_subtitle: "Die Hauptanmeldeinformationen zum Zugriff auf Ihre Umgebung",
    //         pb_information: "Persönliche & Geschäftsinformationen",
    //         pb_subtitle: "Alle Informationen über Ihre Person und Ihr Geschäft",
    //         uid_title: "Ihre Benutzer-ID",
    //         uid_subtitle: "Persönlicher ID-Code",
    //         activity_log: "Aktivitätsprotokoll",
    //         activity_log_subtitle: "Ihr aktuelles Aktivitätsprotokoll",
    //         no_activity: "Keine aktuellen Aktivitäten gefunden",
    //         update_btn: "Aktualisieren"
    //     },
    //     settings: {
    //         settings_title: "Einstellungen"
    //     },             
    // }
};
