// OnBoardingSection.js
import React, { useEffect, useState } from "react";
import { useLanguage } from "../Translations/LenguageContext";
import { FaRegUser, FaRobot, FaFileAlt, FaCreditCard } from "react-icons/fa";

// Map icon names to actual components
const iconMap = {
    FaRegUser: <FaRegUser />,
    FaRobot: <FaRobot />,
    FaFileAlt: <FaFileAlt />,
    FaCreditCard: <FaCreditCard />
};

function OnBoardingSection() {
    const [activeIndex, setActiveIndex] = useState(0);
    const { t } = useLanguage();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % 4);
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="oboarding_container">
            <p className="steps_title">{t.onboarding.title}</p>
            <p className="steps_desc">{t.onboarding.desc}</p>
            <div className="onboarding_sections">
                {t.onboarding.titles.map((title, index) => (
                    <div key={index} className={`card_onboarding ${activeIndex === index ? 'active' : 'inactive'}`}>
                        <div className="card_title">
                            <span className="card_icon">
                                {iconMap[t.onboarding.icons[index]]}
                            </span>
                            <p>{title}</p>
                        </div>
                        <div className="card_text">
                            <p>{t.onboarding.descriptions[index]}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OnBoardingSection;
