import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Spinner from "../../../components/Spinner";
import { useLanguage } from '../../../Translations/LenguageContext';

const DeleteContactModal = ({show, handleClose, onDeleteConfirmed, contactName, contactSurname  }) => {
    const { t } = useLanguage();
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);

    const handleDelete = () => {
        setLoading(true);
        onDeleteConfirmed()
            .finally(() => setLoading(false)); // Ensure loading state is reset after deletion attempt
    };

    return (
        <Modal show={show} onHide={handleClose} className="modal_contacts_delete">
            <div className='header_modal'>
                <div className='modal_title_cont_prop_delete'>
                    <p>{t.contacts.confirmation_delete}</p>
                </div>
                <button className='close_x_modale' onClick={handleClose}>
                    <i className='fa-solid fa-x'></i>
                </button>
            </div>
            <div className='body_modal_delete'>
                <p className='body_delete_modal'>{contactName} {contactSurname}</p>
                {err.display && <p className="error_message">{err.message}</p>}
            </div>

            <div className='footer_modal'>
                <div className='footer_btn_cont'>
                    <button className='save_modal_btn delete_btn' onClick={handleDelete} disabled={loading}>
                        {t.delete}
                    </button>
                    <button className='close_modal_btn' onClick={handleClose} disabled={loading}>
                        {t.properties.close_btn}
                    </button>
                </div>
                {loading && <Spinner loading={loading} err={err}></Spinner>}
            </div>
        </Modal>
    );
};

export default DeleteContactModal;
