import React, { useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import nationData from "../../../Geo/CountriesCities.json";
import stripeNation from "../../../Geo/SupportedStripeCountries.json";

const TenantPersonalInformation = () => {
    const { t } = useLanguage();

    // State for selected country and city list
    const [selectedCountry, setSelectedCountry] = useState("");
    const [cities, setCities] = useState([]);

    // Handle country selection and update cities based on the country
    const handleCountryChange = (event) => {
        const country = event.target.value;
        setSelectedCountry(country);

        // Update cities for the selected country
        if (nationData[country]) {
            setCities(nationData[country]);
        } else {
            setCities([]);
        }
    };

    return (
        <div className="tenant_label_input_cont">
            <p className="new_tenant_title">Personal or Business information</p>
            <p className="description_tenant_title">Insert the tenants personal or business information and contacts</p>
            <div className="tenant_info_container">
                <div className="tenant_info_cont">
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.register_form.name}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.register_form.surname}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.birthday}</label>
                        <input type="date" className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.sex}</label>
                        <select className="tenant_input">
                            <option value="">-</option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                        </select>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.country}</label>
                        <select className="tenant_input" value={selectedCountry} onChange={handleCountryChange}>
                            <option value="">-</option>
                            {Object.keys(nationData).map((country, index) => (
                                <option key={index} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.city}</label>
                        <select className="tenant_input">
                            <option value="">-</option>
                            {cities.map((city, index) => (
                                <option key={index} value={city}>
                                    {city}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.province}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.street}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.n}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.zip}</label>
                        <input className="tenant_input"></input>
                    </div>
                </div>

                <div className="tenant_info_cont">
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.business_name}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.vat}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.country}</label>
                        <select className="tenant_input">
                            <option value="">-</option>
                            {stripeNation.map((country, index) => (
                                <option key={index} value={country.code}>
                                    {country.name}
                                </option>
                            ))}
                        </select>

                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.city}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.province}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.street}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.n}</label>
                        <input className="tenant_input"></input>
                    </div>
                    <div className="tenant_lab_in_cont">
                        <label className="tenant_label">{t.complete_account.zip}</label>
                        <input className="tenant_input"></input>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TenantPersonalInformation;
