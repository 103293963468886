import React from "react";
import { useNavigate } from "react-router-dom";

function MovingArrows({ front, back }) {
    const navigate = useNavigate();

    return (
        <div className="moving_arrow_cont">
            <button
                className="moving_arrows_btn"
                onClick={() => navigate(back)}>
                <i className="fa-solid fa-chevron-left"></i>
            </button>
            <button
                className="moving_arrows_btn"
                onClick={() => navigate(front)}
                disabled={!front || front === "/"}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </button>

        </div>
    );
}

export default MovingArrows;
