import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";
import { BsHouses } from "react-icons/bs";
import AddPropertieModal from "../Property/AddPropertieModal";
import PopupMex from "../SavePopup";
import { Dropdown } from 'react-bootstrap';


function PropertiesList({ fetchProperties, properties, onPropertySelect }) {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [popupActive, setPopupActive] = useState(false);
    const [popupType, setPopupType] = useState("success");

    const [selectedPropertyKey, setSelectedPropertyKey] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedPropertyName, setSelectedPropertyName] = useState(null);
    const [selectedPropertyData, setSelectedPropertyData] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [userId, setUserId] = useState(sessionStorage.getItem("user_id"));



    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handlePropertyAdded = async () => {
        setPopupType("success");
        setPopupActive(true);
        await fetchProperties();
    };

    const handlePopupClose = () => {
        setPopupActive(false);
    };

    const handleEditClick = (key, property) => {
        setSelectedPropertyKey(key);
        setSelectedPropertyData(property);
        setShowModal(true);
    };

    const handleDeleteClick = (key, propertyName) => {
        setSelectedPropertyKey(key);
        setShowDeleteModal(true);
        setSelectedPropertyName(propertyName);
    };


    const handleCheckboxChange = (property) => {
        if (selectedProperty === property) {
            setSelectedProperty(null);
            onPropertySelect(null);
        } else {
            setSelectedProperty(property);
            onPropertySelect(property);
        }
    };

    const NavigateToProperties = () => {
        navigate("/control_panel/properties");
    };

    return (
        <div className="property_container">
            <div className="tenant_property_cont">
                <p className="monthly_payment_title">Properiteà disponibili</p>
                <button className='tenant_add_property_btn' onClick={handleShow}>
                    <i className='fa-solid fa-plus'></i>{t.properties.add_property}
                </button>
            </div>

            <div className="property_choice">
                {Object.keys(properties).length === 0 ? (
                    <div className='no_data_mex_cont'>
                        <p className='no_data_mex'>{t.properties.no_property}</p>
                        <div className='no_data_img'></div>
                        {/* <button className='tenant_go_property_btn' onClick={NavigateToProperties}>
                            <i><BsHouses /></i>View properties
                        </button> */}

                    </div>
                ) : (
                    Object.entries(properties).map(([id, property]) => (
                        <div key={id} className="property_item">
                            <div className="checkBox_cont">
                                <input
                                    type="checkbox"
                                    checked={selectedProperty === property}
                                    onChange={() => handleCheckboxChange(property)}
                                />
                            </div>
                            <p className="title_prop">{property.title_property}</p>
                            <div className="tenant_prop_det">
                                <p>{t.properties[property.unit_type]}</p>
                                <p>{property.mt2_property}mt2</p>
                            </div>
                            <div className="tenant_prop_det">
                                <p><i className="fa-solid fa-map-pin"></i> {property.nation_property} - {property.city_property}, {property.street_property} {property.number_property}, {property.zip_property}</p>
                            </div>
                            <div className="tenant_prop_det">
                                <div className="prop_expenses_cont">
                                    <p className="title_prop_expenses">Canone</p>
                                    <p className="prop_ammount_expenses">{property.price_property}{property.currency_property}</p>
                                </div>

                                <div className="prop_expenses_cont">
                                    <p className="title_prop_expenses">Spese</p>
                                    <p className="prop_ammount_expenses">{property.expenses_property}{property.currency_property}</p>
                                </div>

                                <div className="prop_expenses_cont">
                                    <p className="title_prop_expenses">Deposito</p>
                                    <p className="prop_ammount_expenses">{property.deposit_property}{property.currency_property}</p>
                                </div>

                                <div className="prop_expenses_cont">
                                    <p className="title_prop_expenses">Commissioni</p>
                                    <p className="prop_ammount_expenses">{property.commissions_property}{property.commission_type_property}</p>
                                </div>
                            </div>
                            <div className='btn_tenant_prop_list'>
                                <div className='btn_prop_cont'>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            className='custom-dropdown-toggle'
                                            as="div"
                                        >
                                            <i className='fa-solid fa-ellipsis-vertical'></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='custom-dropdown-menu'>
                                            <Dropdown.Item
                                                className='drop_down_item'
                                                onClick={() => handleEditClick(property.key, property)}
                                            >
                                                <i className='fa-solid fa-pencil'></i> {t.edit}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className='drop_down_item'
                                                onClick={NavigateToProperties}
                                            >
                                                <i><BsHouses /></i> Gestisci proprietà
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <AddPropertieModal
                show={showModal}
                handleClose={handleClose}
                onPropertieAddedOrUpdated={handlePropertyAdded}
                propertyData={selectedPropertyData}
            />
            <PopupMex active={popupActive} type={popupType} onClose={handlePopupClose} />
        </div>
    );
}

export default PropertiesList;
