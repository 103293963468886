// MainContent.js
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { RiSecurePaymentFill } from "react-icons/ri";
import { MdMarkEmailRead } from "react-icons/md";
import { FiMessageSquare } from "react-icons/fi";
import { PiCalendarDotsBold, PiHouse } from "react-icons/pi";
import { AiOutlinePieChart } from "react-icons/ai";
import { IoPeople } from "react-icons/io5";
import { useLanguage } from "../Translations/LenguageContext"; 

function MainContent() {
    const navigate = useNavigate();
    const scrollingRef = useRef(null);
    const { t } = useLanguage(); // Correctly get translations from context

    useEffect(() => {
        const scroller = scrollingRef.current;
        const elements = scroller.querySelectorAll('.widget_element');
        const elementWidth = elements[0].offsetWidth + parseInt(window.getComputedStyle(elements[0]).marginRight);
        const seconds = 120;

        // Duplicate the elements for smooth looping
        const duplicatedElements = scroller.innerHTML + scroller.innerHTML;
        scroller.innerHTML = duplicatedElements;

        // Set up the animation
        scroller.style.animation = `scroll ${seconds}s linear infinite`;

        // Reset animation when it ends to avoid a visual jump
        const handleAnimationIteration = () => {
            scroller.style.animation = 'none';
            // Force reflow
            const reflowTrigger = scroller.offsetHeight; // Assign to a variable to avoid ESLint error
            scroller.style.animation = `scroll ${seconds}s linear infinite`;
        };

        scroller.addEventListener('animationiteration', handleAnimationIteration);

        return () => {
            scroller.removeEventListener('animationiteration', handleAnimationIteration);
        };
    }, []);

    return (
        <div className="main_content">
            <div className="main_section">
                <div className="scroller_cont">
                    <p className="main_title">
                        {t.main_title}<span className="ia_span">{t.ai}</span>
                    </p>
                </div>
                <div className="descriptions_sec">
                    <p className="main_description">
                        {t.main_description}
                    </p>
                </div>
            </div>
            <div className="widget_scroller">
                <div className="scrolling" ref={scrollingRef}>
                    <div className="widget_element"><span><PiHouse /></span> <p>{t.property_management}</p></div>
                    <div className="widget_element"><span><IoPeople /></span> <p>{t.tenant_oversight}</p></div>
                    <div className="widget_element"><span><RiSecurePaymentFill /></span> <p>{t.secure_transactions}</p></div>
                    <div className="widget_element"><span><FiMessageSquare /></span> <p>{t.instant_messaging}</p></div>
                    <div className="widget_element"><span><MdMarkEmailRead /></span> <p>{t.email_notifications}</p></div>
                    <div className="widget_element"><span><PiCalendarDotsBold /></span> <p>{t.calendar_events}</p></div>
                    <div className="widget_element"><span><AiOutlinePieChart /></span> <p>{t.ai_driven_analytics}</p></div>
                </div>
            </div>
        </div>
    );
}

export default MainContent;
