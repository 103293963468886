import React, { useState, useEffect, useRef } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import nationData from "../../../Geo/CountriesCities.json";
import stripeNation from "../../../Geo/SupportedStripeCountries.json";
import PopupMex from "../SavePopup";
import Spinner from "../../../components/Spinner";

function Profile() {
    const { t } = useLanguage();
    const [userId] = useState(sessionStorage.getItem("user_id"));
    const [isModified, setIsModified] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [popupActive, setPopupActive] = useState(false);
    const [popupType, setPopupType] = useState("success");
    const [iconClass, setIconClass] = useState('fa-solid fa-copy');
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);
    const userIdRef = useRef(null);


    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        birthday: '',
        sex: '',
        personal_nation: '',
        personal_city: '',
        personal_prov: '',
        personal_street: '',
        personal_num: '',
        personal_zip: '',
        business_name: '',
        vat: '',
        business_nation: '',
        business_city: '',
        business_prov: '',
        business_street: '',
        business_num: '',
        business_zip: '',
        updated_at: [],
    });

    useEffect(() => {
        setErr({ message: "", display: false });
        setLoading(true)
        fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_user_data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userId: userId })
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setLoading(false)
                    setErr({ message: "", display: false });
                    setFormData({
                        name: data.name || '',
                        surname: data.surname || '',
                        email: data.email || '',
                        password: data.password || '',
                        birthday: data.birthday || '',
                        sex: data.sex || '',
                        personal_nation: data.personal_info.nation || '',
                        personal_city: data.personal_info.city || '',
                        personal_prov: data.personal_info.prov || '',
                        personal_street: data.personal_info.street || '',
                        personal_num: data.personal_info.num || '',
                        personal_zip: data.personal_info.zip || '',
                        business_name: data.business_info.business_name || '',
                        vat: data.business_info.vat || '',
                        business_nation: data.business_info.nation || '',
                        business_city: data.business_info.city || '',
                        business_prov: data.business_info.prov || '',
                        business_street: data.business_info.street || '',
                        business_num: data.business_info.num || '',
                        business_zip: data.business_info.zip || '',
                        updated_at: data.updated_at || [],
                    });
                }
            })
            .catch(error => {
                setLoading(false);
                setErr({ message: t.register_form.problem_back, display: true });
            });            
    }, [userId]);


    const handleNationChange = (e) => {
        const selectedNation = e.target.value;
        setFormData({
            ...formData,
            personal_nation: selectedNation,
            personal_city: ''
        });
    };

    const handleCityChange = (e) => {
        setFormData({
            ...formData,
            personal_city: e.target.value
        });
    };

    const getCityOptions = (nation) => {
        return nationData[nation] || [];
    };

    const handleNationStripe = (e) => {
        const selectedNation = e.target.value;
        setFormData({
            ...formData,
            business_nation: selectedNation,
            business_city: ''
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setIsModified(true);
    };

    const handleUpdate = () => {
        setLoading(true)
        fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/modify_user", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: userId,
                ...formData
            })
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false)
                setErr({ message: "", display: false });
                if (data.message === "OK") {
                    setIsModified(false);
                    setPopupType("success");
                    setPopupActive(true);
                } else {
                    alert('Error updating profile: ' + data.error);
                }
            })
            .catch(error => {
                setLoading(false);
                setErr({ message: t.register_form.problem_back, display: true });
            });            
    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{10,20}$/;

    const validateEmail = (email) => {
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        return passwordRegex.test(password);
    };

    const setFieldError = (field, hasError) => {
        field.style.borderBottom = hasError ? "2px solid var(--danger)" : "";
        return hasError;
    };

    const CheckFields = () => {
        const fields = {
            email: document.getElementById("email"),
            password: document.getElementById("password"),
            name: document.getElementById("name"),
            surname: document.getElementById("surname"),
            birthday: document.getElementById("birthday"),
            sex: document.getElementById("sex"),
            personal_nation: document.getElementById("personal_nation"),
            personal_city: document.getElementById("personal_city"),
            personal_prov: document.getElementById("personal_prov"),
            personal_street: document.getElementById("personal_street"),
            personal_num: document.getElementById("personal_num"),
            personal_zip: document.getElementById("personal_zip"),
            business_name: document.getElementById("business_name"),
            vat: document.getElementById("vat"),
            business_nation: document.getElementById("business_nation"),
            business_city: document.getElementById("business_city"),
            business_prov: document.getElementById("business_prov"),
            business_street: document.getElementById("business_street"),
            business_num: document.getElementById("business_num"),
            business_zip: document.getElementById("business_zip")
        };

        let hasError = false;
        let emailError = false;
        let passwordError = false;

        if (fields.email.value === '') {
            hasError = setFieldError(fields.email, true);
        } else if (!validateEmail(fields.email.value)) {
            hasError = setFieldError(fields.email, true);
            emailError = true;
        } else {
            setFieldError(fields.email, false);
        }

        if (fields.password.value !== '' && !validatePassword(fields.password.value)) {
            hasError = setFieldError(fields.password, true);
            passwordError = true;
        } else {
            setFieldError(fields.password, false);
        }

        Object.keys(fields).forEach(key => {
            if (key !== 'email' && key !== 'password' && fields[key].value === '') {
                hasError = setFieldError(fields[key], true);
            } else {
                setFieldError(fields[key], false);
            }
        });

        if (hasError) {
            if (emailError) {
                setErr({ message: t.register_form.email_format, display: true });
            } else if (passwordError) {
                setErr({ message: t.register_form.password_err, display: true });
            } else {
                setErr({ message: t.register_form.red_fields, display: true });
            }
        } else {
            setErr({ message: "", display: false });
            handleUpdate();
        }
    };



    const handlePopupClose = () => {
        setPopupActive(false);
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(userId);
            setIconClass('fa-solid fa-check');
            setTimeout(() => {
                setIconClass('fa-solid fa-copy');
            }, 3000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.profile.profile_title}</p>
            <div className="profile_container">
                <div className="btn_cont_profile">
                    <Spinner loading={loading} err={err}></Spinner>
                    {isModified && (
                        <button className="save_profile_updates_btn" onClick={CheckFields}>{t.profile.update_btn}</button>
                    )}
                </div>
                <div className="section_profile">
                    <p className="section_profile_title">{t.profile.access}</p>
                    <p className="under_title_profile">{t.profile.main_credentials_subtitle}</p>
                    <div className="section_profile_double_face">
                        <div className="section_input_cont">
                            <div className="label_input_cont_profile">
                                <label className="label_profile">Email</label>
                                <input id="email" type="email" className="input_profile" value={formData.email || ''} name="email" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">Password</label>
                                <input id="password" type={showPassword ? "text" : "password"} className="input_profile" placeholder="**********************" name="password" onChange={handleInputChange} />
                                <button className="show_passowrd_profile" onClick={togglePasswordVisibility}>
                                    <i className={`fa-regular fa-eye${showPassword ? "" : "-slash"}`}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section_profile">
                    <p className="section_profile_title">{t.profile.pb_information}</p>
                    <p className="under_title_profile">{t.profile.pb_subtitle}</p>
                    <div className="section_profile_double_face">
                        <div className="section_input_cont">
                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.register_form.name}</label>
                                <input id="name" type="text" className="input_profile" value={formData.name || ''} name="name" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.register_form.surname}</label>
                                <input id="surname" type="text" className="input_profile" value={formData.surname || ''} name="surname" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.birthday}</label>
                                <input id="birthday" type="date" className="input_profile" value={formData.birthday || ''} name="birthday" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.sex}</label>
                                <select id="sex" className="input_profile" value={formData.sex || ''} name="sex" onChange={handleInputChange}>
                                    <option value={""}>-</option>
                                    <option value={"M"}>M</option>
                                    <option value={"F"}>F</option>
                                </select>
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.country}</label>
                                <select
                                    id="personal_nation"
                                    className="input_profile"
                                    value={formData.personal_nation || ''}
                                    name="personal_nation"
                                    onChange={(e) => { handleInputChange(e); handleNationChange(e); }}
                                    disabled={!Object.keys(nationData).length}
                                >
                                    <option value={""}>-</option>
                                    {Object.keys(nationData).map((nation) => (
                                        <option key={nation} value={nation}>
                                            {nation}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.city}</label>
                                <select
                                    id="personal_city"
                                    className="input_profile"
                                    value={formData.personal_city || ''}
                                    name="personal_city"
                                    onChange={(e) => { handleInputChange(e); handleCityChange(e); }}
                                    disabled={!formData.personal_nation}
                                >
                                    <option value={""}>-</option>
                                    {getCityOptions(formData.personal_nation).map((city, index) => (
                                        <option key={index} value={city}>
                                            {city}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.province}</label>
                                <input id="personal_prov" type="text" className="input_profile" value={formData.personal_prov} name="personal_prov" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.street}</label>
                                <input id="personal_street" type="text" className="input_profile" value={formData.personal_street} name="personal_street" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.n}</label>
                                <input id="personal_num" type="text" className="input_profile" value={formData.personal_num} name="personal_num" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.zip}</label>
                                <input id="personal_zip" type="text" className="input_profile" value={formData.personal_zip} name="personal_zip" onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="section_input_cont">
                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.business_name}</label>
                                <input id="business_name" type="text" className="input_profile" value={formData.business_name || ''} name="business_name" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.vat}</label>
                                <input id="vat" type="text" className="input_profile" value={formData.vat || ''} name="vat" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.country}</label>
                                <select id="business_nation"
                                    className="input_profile"
                                    value={formData.business_nation || ''}
                                    name="business_nation"
                                    onChange={(e) => { handleInputChange(e); handleNationStripe(e); }}
                                    disabled={!Object.keys(stripeNation).length}
                                >
                                    <option value={""}>-</option>
                                    {stripeNation.map((nation) => (
                                        <option key={nation.code} value={nation.code}>
                                            {nation.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.city}</label>
                                <input id="business_city"
                                    className="input_profile"
                                    value={formData.business_city || ''}
                                    name="business_city"
                                    onChange={handleInputChange}
                                >
                                </input>
                            </div>
                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.province}</label>
                                <input id="business_prov" type="text" className="input_profile" value={formData.business_prov || ''} name="business_prov" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.street}</label>
                                <input id="business_street" type="text" className="input_profile" value={formData.business_street || ''} name="business_street" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.n}</label>
                                <input id="business_num" type="text" className="input_profile" value={formData.business_num || ''} name="business_num" onChange={handleInputChange} />
                            </div>

                            <div className="label_input_cont_profile">
                                <label className="label_profile">{t.complete_account.zip}</label>
                                <input id="business_zip" type="text" className="input_profile" value={formData.business_zip || ''} name="business_zip" onChange={handleInputChange} />
                            </div>
                        </div>

                    </div>
                    <div className="section_profile">
                        <p className="section_profile_title">{t.profile.uid_title}</p>
                        <p className="under_title_profile">{t.profile.uid_subtitle}</p>

                        <div className="user_id_cont">
                            <p className="user_id">{userId}</p>
                            <button className="copy_user_id_btn" onClick={copyToClipboard}>
                                <i className={iconClass}></i>
                            </button>
                        </div>
                    </div>

                    <div className="section_profile">
                        <p className="section_profile_title">{t.profile.activity_log}</p>
                        <p className="under_title_profile">{t.profile.activity_log_subtitle}</p>

                        <div className="activity_log_container">
                            {Array.isArray(formData.updated_at) && formData.updated_at.length > 0 ? (
                                formData.updated_at.map((entry, index) => (
                                    <div
                                        key={index}
                                        className={`activity_log_entry ${entry.status_update === 'OK' ? 'success_activity' :
                                            entry.status_update === 'KO' ? 'error_activity' : ''
                                            }`}
                                    >
                                        <span className="activity_check">
                                            <i className={`fa-solid ${entry.status_update === 'OK' ? 'fa-check' : 'fa-x'}`}></i>
                                        </span>
                                        <p className="activity_log_cell timestamp">{entry.timestamp}</p>
                                        <p className="activity_log_cell update_type">{entry.update_type}</p>
                                    </div>
                                ))
                            ) : (
                                <div className='no_data_mex_cont'>
                                    <p className='no_data_mex'>{t.profile.no_activity}</p>
                                    {/* <div className='no_data_img'></div> */}
                                </div>
                            )}
                        </div>



                    </div>
                </div>
            </div>
            <PopupMex active={popupActive} type={popupType} onClose={handlePopupClose} />
        </div>
    );
}

export default Profile;
