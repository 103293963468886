import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { RiContactsBook3Line, RiMessage3Line } from "react-icons/ri";

// Utility function to format email addresses
const formatEmail = (email) => {
    return email.replace(/_(com|it|org|net|edu)$/, '.$1');
};

function MessagesOpenChats({ userId, onContactSelect }) {
    const { t } = useLanguage();
    const [contacts, setContacts] = useState([]);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null); // Track the selected contact
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [showContacts, setShowContacts] = useState(true); // Toggle between contacts and messages

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_contact', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: userId }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const contactsArray = Object.entries(data.contacts || {}).map(([key, value]) => ({
                    key,
                    ...value
                }));
                setContacts(contactsArray);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        const fetchMyMessages = async () => {
            try {
                const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_my_chats', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ user_id: userId }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const messagesArray = Object.entries(data.messages || {}).flatMap(([email, chatData]) =>
                    Object.entries(chatData).map(([key, messageDetails]) => ({
                        key,
                        email: formatEmail(email),
                        ...messageDetails
                    }))
                );
                setMessages(messagesArray);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchContacts();
        fetchMyMessages();
    }, [userId]);

    const handleContactClick = (contact) => {
        setSelectedContact(contact.key);
        onContactSelect(contact); // Notify parent about the selected contact
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredContacts = contacts.filter(contact =>
        contact.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Group messages by email and get the most recent message for each
    const groupedMessages = messages.reduce((acc, message) => {
        if (!acc.has(message.email)) {
            acc.set(message.email, message);
        } else {
            const existingMessage = acc.get(message.email);
            // Update with the most recent message if necessary
            if (new Date(message.timestamp) > new Date(existingMessage.timestamp)) {
                acc.set(message.email, message);
            }
        }
        return acc;
    }, new Map());

    const filteredMessages = Array.from(groupedMessages.values()).filter(message =>
        message.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return 'N/A';

        const now = new Date();
        const date = new Date(timestamp);

        // Check if the date is today
        const isToday = now.toDateString() === date.toDateString();

        // Format date accordingly
        if (isToday) {
            // Show only hour and minutes
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
            // Show day and month
            return date.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
        }
    };

    if (loading) return (
        <div className="open_chat_cont">
            <div className='loading_mex_cont'>
                <p className='loading_mex'>{t.loading}</p>
                <i className='fa-solid fa-box-open'></i>
            </div>
        </div>
    );

    if (error) return (
        <div className="open_chat_cont">
            <div className='loading_mex_cont_err'>
                <p>{t.error}</p>
                <i className='fa-solid fa-box-open'></i>
            </div>
        </div>
    );

    return (
        <div className="open_chat_cont">
            <p className="open_chat_title">FlatJourney AI <i className="fa-solid fa-comments"></i></p>
            <div className="toggle_buttons_chat">

                <button
                    className={`toggle_btn ${showContacts ? 'active' : ''}`}
                    onClick={() => setShowContacts(true)}
                >
                    <i><RiMessage3Line /></i>
                </button>
                <button
                    className={`toggle_btn ${!showContacts ? 'active' : ''}`}
                    onClick={() => setShowContacts(false)}
                >
                    <i><RiContactsBook3Line /></i>
                </button>

            </div>
            <div className="search_chat">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                    className="filter_chat"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="open_chats">
                {!showContacts ? (
                    filteredContacts.map(contact => (
                        <div
                            key={contact.key}
                            className="chat_prev_cont"
                            style={{
                                borderBottom: selectedContact === contact.key ? '1px solid lightGrey' : '',
                                borderTop: selectedContact === contact.key ? '1px solid lightGrey' : '',
                                background: selectedContact === contact.key ? "var(--milkyWhite)" : ""
                            }}
                            onClick={() => handleContactClick(contact)}
                        >
                            <div className="chat_avatar">
                                {/* <div className="avatar_chat_img"></div> */}
                            </div>
                            <div className="chat_info_cont">
                                <div className="chat_info">
                                    <p className="chat_name">{contact.email}</p>
                                    <p className="last_mex">{t.properties[contact.unit_type] || 'No messages yet...'}</p>
                                </div>
                                <div className="chat_hour_cont">
                                    {/* <p className="chat_hour">{contact.lastMessageTime || 'N/A'}</p> */}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    filteredMessages.map(message => (
                        <div
                            key={message.key}
                            className="chat_prev_cont"
                            style={{
                                borderBottom: selectedContact === message.key ? '1px solid lightGrey' : '',
                                borderTop: selectedContact === message.key ? '1px solid lightGrey' : '',
                                background: selectedContact === message.key ? "var(--milkyWhite)" : ""
                            }}
                            onClick={() => handleContactClick(message)}
                        >
                            <div className="chat_avatar">
                                {/* <div className="avatar_chat_img"></div> */}
                            </div>
                            <div className="chat_info_cont">
                                <div className="chat_info">
                                    {console.log(message)}
                                    <p className="chat_name">{message.email}</p>
                                    <p className="last_mex">{message.message || 'No messages yet...'}</p>
                                </div>
                                <div className="chat_hour_cont">
                                    <p className="chat_hour">{formatTimestamp(message.timestamp)}</p>
                                </div>
                            </div>
                        </div>
                    ))
                )}
                <button className="new_chat_btn"><i className="fa-solid fa-plus"></i></button>
            </div>
        </div>
    );
}

export default MessagesOpenChats;
