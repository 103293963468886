// Footer.js
import React, { useEffect } from "react";
import { FaBoxOpen } from "react-icons/fa";
import { useLanguage } from "../Translations/LenguageContext";
import { MdMarkEmailRead } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();
    const { t, changeLanguage, language } = useLanguage();

    // useEffect(() => {
    //     // Function to fetch user location and set language
    //     const detectLanguageByLocation = async () => {
    //         try {
    //             // Use an external service or API to detect location
    //             const response = await fetch("https://ipapi.co/json/");
    //             const data = await response.json();

    //             if (data.country_code === "IT") {
    //                 changeLanguage("it");
    //             } else {
    //                 changeLanguage("en");
    //             }
    //         } catch (error) {
    //             // Fallback in case of error or failure
    //             console.error("Error detecting location:", error);
    //             changeLanguage("en");
    //         }
    //     };

    //     detectLanguageByLocation();
    // }, [changeLanguage]);

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value);
    };

    return (
        <div className="footer_homepage">
            <div className="footer_container">
                <div className="sections_footer sf1">
                    <div className="section_footer_text">
                        <p className="brand_logo"><FaBoxOpen /> FlatJourney<span className="ia_span">AI</span></p>
                        <p className="brand_rights">{t.footer.brand_rights}</p>
                        <p className="brand_rights">{t.footer.address}</p>
                        <p className="brand_rights">{t.footer.vat}</p>
                        <select className="language_select" onChange={handleLanguageChange} value={language}>
                            <option value="en">English</option>
                            <option value="it">Italiano</option>
                            {/* <option value="de">Deutsch</option> */}
                            {/* <option value="fr">Français</option> */}
                        </select>
                    </div>

                </div>

                <div className="sections_footer">
                    <div className="section_footer_text">
                        <p className="footer_titles">{t.footer.about_us}</p>
                        <div className="link_cont_footer">
                            <p className="link_footer"  onClick={() => navigate("/aboutus")}>{t.footer.who}</p>
                            <p className="link_footer" onClick={() => navigate("/open_positions")}>{t.footer.jobs}</p>
                            <p className="link_footer" onClick={() => navigate("/blog")}>Blog</p>
                        </div>
                    </div>
                  
                </div>
                <div className="sections_footer">
                <div className="section_footer_text">
                        <p className="footer_titles">Flat-Products</p>
                        <div className="link_cont_footer">
                            <p className="link_footer" onClick={() => navigate("/flatjourneyAI")}>FlatJourneyAI</p>
                            <p className="link_footer" onClick={() => navigate("/flatjourneyPMS")}>FlatJourneyPMS</p>
                            <p className="link_footer" onClick={() => navigate("/flatPay")}>FlatPay</p>
                        </div>
                    </div>
                   

                </div>
                <div className="sections_footer">
                    <div className="section_footer_text">

                        <p className="footer_titles">{t.footer.get_in_touch}</p>
                        <div className="logo_social_container">
                            <i className="fa-brands fa-twitter"></i>
                            <i className="fa-brands fa-instagram"></i>
                            <i className="fa-brands fa-facebook"></i>
                            <i className="fa-brands fa-linkedin"></i>
                        </div>
                        <p className="newsletter_title">Newsletter</p>
                        <div className="newsletter_cont">
                            <input className="input_newsletter" placeholder="jhondoe@gmail.com"></input>
                            <buton className="button_newsletter"><MdMarkEmailRead /></buton>
                        </div>
                    </div>
                    <div className="section_footer_text">
                        <p className="footer_titles">Legal</p>
                        <div className="link_cont_footer">
                            <p className="link_footer" onClick={() => navigate("/terms_conditions")}>{t.footer.terms_conditions}</p>
                            <p className="link_footer" onClick={() => navigate("/privacy")}>Privacy</p>
                            <p className="link_footer" onClick={() => navigate("/cookies")}>Cookies</p>
                            <p className="link_footer" onClick={() => navigate("/legal_notes")}>{t.footer.legal_notes}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
