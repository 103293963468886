import React, { useState, useEffect } from "react";
import MovingArrows from "../../MovingArrows";
import nationData from "../../../Geo/CountriesCities.json";
import { useLanguage } from "../../../Translations/LenguageContext";
import { BiUser } from "react-icons/bi";
import Spinner from "../../../components/Spinner";
import { FaQuinscape } from "react-icons/fa";

function PersonalInfoForm({ setCurrentStep }) {
    const { t } = useLanguage();
    const [selectedNation, setSelectedNation] = useState("");
    const [birthday, setBirthday] = useState("");
    const [sex, setSex] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [street, setStreet] = useState("");
    const [num, setNum] = useState("");
    const [zip, setZip] = useState("");
    const [err, setErr] = useState({ message: "", display: false });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchUserData();
    }, []);

    const setFieldBorderStyle = (id, value) => {
        const element = document.getElementById(id);
        if (value) {
            element.style.borderLeft = "3px solid var(--mainColor)";
            element.style.borderBottomLeftRadius = "3px solid var(--mainColor)";
            element.style.borderTopLeftRadius = "3px solid var(--mainColor)";
        } else {
            element.style.border = "";
        }
    };

    const fetchUserData = () => {
        const userId = sessionStorage.getItem("user_id");
        if (!userId) {
            console.error("User ID not found in session storage");
            return;
        }

        setLoading(true);
        fetch(`https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_owner_personal_info_route?user_id=${userId}`)
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data) {
                    const birthdayVal = data.birthday || "";
                    const sexVal = data.sex || "";
                    const nationVal = data.nation || "";
                    const cityVal = data.city || "";
                    const provinceVal = data.prov || "";
                    const streetVal = data.street || "";
                    const numVal = data.num || "";
                    const zipVal = data.zip || "";

                    setBirthday(birthdayVal);
                    setSex(sexVal);
                    setSelectedNation(nationVal);
                    setCity(cityVal);
                    setProvince(provinceVal);
                    setStreet(streetVal);
                    setNum(numVal);
                    setZip(zipVal);

                    // Set border styles
                    setFieldBorderStyle("birthday_val", birthdayVal);
                    setFieldBorderStyle("sex_val", sexVal);
                    setFieldBorderStyle("nation_val", nationVal);
                    setFieldBorderStyle("city_val", cityVal);
                    setFieldBorderStyle("prov_val", provinceVal);
                    setFieldBorderStyle("street_val", streetVal);
                    setFieldBorderStyle("num_val", numVal);
                    setFieldBorderStyle("zip_val", zipVal);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error("Request failed:", error);
                setErr({ message: t.register_form.problem_back, display: true });
            });
    };

    const handleNationChange = (e) => {
        setSelectedNation(e.target.value);
        setCity(""); // Clear city when nation changes
        setFieldBorderStyle("city_val", ""); // Reset city border
    };

    const handleNextStep = () => {
        CheckFields();
    };

    function CheckFields() {
        let errVal = false;

        const checkField = (value, id) => {
            const element = document.getElementById(id);
            if (value === "") {
                element.style.borderleft = "1px solid var(--danger)";
                errVal = true;
            } else {
                element.style.border = "";
            }
        };

        checkField(birthday, "birthday_val");
        checkField(sex, "sex_val");
        checkField(selectedNation, "nation_val");
        checkField(city, "city_val");
        checkField(province, "prov_val");
        checkField(street, "street_val");
        checkField(num, "num_val");
        checkField(zip, "zip_val");

        if (!errVal) {
            updateUserData();
        }
    }

    const updateUserData = () => {
        setErr({ message: "", display: false });
        setLoading(true);
        const userId = sessionStorage.getItem("user_id");
        if (!userId) {
            console.error("User ID not found in session storage");
            return;
        }

        const dataToUpdate = {
            user_id: userId,
            birthday,
            sex,
            nation: selectedNation,
            city,
            prov: province,
            street,
            num,
            zip,
        };

        fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/owner_personal_information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToUpdate),
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.message === "OK") {
                    setCurrentStep(2);
                } else {
                    setErr({ message: t.register_form.problem_back, display: true });
                    console.error("Error updating user type:", data.error);
                }
            })
            .catch(error => {
                setLoading(false);
                setErr({ message: t.register_form.problem_back, display: true });
                console.error("Request failed:", error);
            });
    };

    return (
        <div className="form_complete_reg">
            <p className="form_complete_reg_title">
                <BiUser /> {t.complete_account.personal_info}
            </p>
            <div className="form_complete_reg_body">
                <div className="input_label">
                    <label>{t.complete_account.birthday}</label>
                    <input
                        id="birthday_val"
                        type="date"
                        value={birthday}
                        onChange={(e) => {
                            setBirthday(e.target.value);
                            setFieldBorderStyle("birthday_val", e.target.value);
                        }}
                    />
                </div>
                <div className="input_label">
                    <label>{t.complete_account.sex}</label>
                    <select
                        id="sex_val"
                        value={sex}
                        onChange={(e) => {
                            setSex(e.target.value);
                            setFieldBorderStyle("sex_val", e.target.value);
                        }}
                    >
                        <option value={""}>-</option>
                        <option value={"M"}>M</option>
                        <option value={"F"}>F</option>
                    </select>
                </div>
                <div className="input_label">
                    <label>{t.complete_account.country}</label>
                    <select
                        id="nation_val"
                        value={selectedNation}
                        onChange={handleNationChange}
                    >
                        <option value="" disabled>-</option>
                        {Object.keys(nationData).map((nation) => (
                            <option key={nation} value={nation}>
                                {nation}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="input_label">
                    <label>{t.complete_account.city}</label>
                    <select
                        id="city_val"
                        value={city}
                        onChange={(e) => {
                            setCity(e.target.value);
                            setFieldBorderStyle("city_val", e.target.value);
                        }}
                    >
                        <option value="" disabled>-</option>
                        {selectedNation &&
                            nationData[selectedNation].map((city) => (
                                <option key={city} value={city}>
                                    {city}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="input_label">
                    <label>{t.complete_account.province}</label>
                    <input
                        id="prov_val"
                        type="text"
                        value={province}
                        onChange={(e) => {
                            setProvince(e.target.value);
                            setFieldBorderStyle("prov_val", e.target.value);
                        }}
                    />
                </div>
                <div className="input_label">
                    <label>{t.complete_account.street}</label>
                    <div className="street_num_cont">
                        <input
                            id="street_val"
                            type="text"
                            value={street}
                            onChange={(e) => {
                                setStreet(e.target.value);
                                setFieldBorderStyle("street_val", e.target.value);
                            }}
                        />
                        <div className="num_label_cont">
                            <label>{t.complete_account.n}</label>
                            <input
                                id="num_val"
                                type="text"
                                value={num}
                                onChange={(e) => {
                                    setNum(e.target.value);
                                    setFieldBorderStyle("num_val", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="input_label">
                    <label>{t.complete_account.zip}</label>
                    <input
                        id="zip_val"
                        type="text"
                        value={zip}
                        onChange={(e) => {
                            setZip(e.target.value);
                            setFieldBorderStyle("zip_val", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="steps_navigation">
                <div></div>
                <Spinner loading={loading} err={err}></Spinner>
                <button className="moving_arrows_btn" onClick={handleNextStep}>
                    <i className="fa-solid fa-chevron-right"></i>
                </button>
            </div>
        </div>
    );
}

export default PersonalInfoForm;
