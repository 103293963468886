import React, { useState, useRef, useEffect } from "react";
import { IoSparkles } from "react-icons/io5";
import AIModal from "./AIModal";

function MessagesChat({ userId, selectedContact, messages, setMessages }) {
    const [messageText, setMessageText] = useState('');
    const [sending, setSending] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [highlightedMessageIndex, setHighlightedMessageIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const messageRefs = useRef([]);

    const handleSendMessage = async () => {
        if (!messageText.trim()) return;

        setSending(true);

        const newMessage = {
            sender: true,
            message: messageText,
            timestamp: new Date().toISOString(),
        };

        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/send_message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sender: true,
                    userId: userId,
                    user_email: selectedContact.email,
                    message: messageText,
                    timestamp: new Date().toISOString(),
                }),
            });

            if (response.status === 404) {
                console.log("No receiver");
                return;
            }else if (!response.ok) {
                throw new Error('Network response was not ok');
            }else{
                setMessageText('');
                setMessages((prevMessages) => [...prevMessages, newMessage]);
            }

        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setSending(false);
        }
    };

    const handleInputChange = (event) => {
        setMessageText(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        if (messages.length > 0) {
            messageRefs.current[messages.length - 1].scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        if (searchQuery) {
            const messageIndex = messages.findIndex(message =>
                message.message.toLowerCase().includes(searchQuery.toLowerCase())
            );

            if (messageIndex !== -1) {
                messageRefs.current[messageIndex].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });

                setHighlightedMessageIndex(messageIndex);

                setTimeout(() => {
                    setHighlightedMessageIndex(null);
                }, 3000);
            }
        }
    }, [searchQuery, messages]);

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const handleShowModal = () => setShowModal(true); // Show the modal
    const handleCloseModal = () => setShowModal(false); // Hide the modal

    // Function to handle AI response from modal
    const handleAiResponse = async (response) => {
        if (!response.trim()) return;

        const newMessage = {
            sender: true,
            message: response,
            timestamp: new Date().toISOString(),
        };

        try {
            const apiResponse = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/send_message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sender: true,
                    userId: userId,
                    user_email: selectedContact.email,
                    message: response,
                    timestamp: new Date().toISOString(),
                }),
            });

            if (!apiResponse.ok) {
                throw new Error('Network response was not ok');
            }

            setMessages((prevMessages) => [...prevMessages, newMessage]);

        } catch (error) {
            console.error('Error sending AI response:', error);
        }
    };

    if (selectedContact?.email === undefined) {
        return (
            <div className="empty_chat_page">
                <div className="empty_desc_cont">
                    <p className="empty_desc_title">FlatJourney<span className="ia_span">AI <i className="fa-solid fa-comments"></i> </span></p>
                    <p className="empty_desc_desc">Answer messages and book appointments with AI</p>
                </div>
            </div>
        );
    }

    return (
        <div className="chat_cont">
            <div className="open_current_chat">
                <div className="header_cont">
                    <div className="chat_header">
                        <div className="chat_user_info">
                            <div className="chat_avatar">
                                {/* <div className="avatar_chat_img"></div> */}
                            </div>
                            <p className="chat_selected_user">{selectedContact?.email || 'Unknown'}</p>
                        </div>
                        <div className='filter_input_cont'>
                            <i className='fa-solid fa-magnifying-glass'></i>
                            <input
                                className='search_bar_input'
                                placeholder='Search for something...'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="chat_view">
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            ref={(el) => (messageRefs.current[index] = el)}
                            className={message.sender ? "mex_sended" : "mex_recieved"}
                        >
                            <div className="entire_message">
                                <p style={{
                                    boxShadow: highlightedMessageIndex === index ? '2px 2px 20px 2px var(--secondColor)' : '',
                                    transition: 'box-shadow 0.3s ease',
                                }}>{message.message}</p>
                                <span className="message_timestamp">{formatTimestamp(message.timestamp)}</span>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="chat_input_container">
                    <div className="chat_input_cont">
                        <span className="sparkles_ai" onClick={handleShowModal}><IoSparkles />AI</span>
                        <input
                            className="enter_text_chat_input"
                            placeholder="Scrivi qualcosa..."
                            value={messageText}
                            onChange={handleInputChange}
                        />
                        <button
                            className="send_mex_btn"
                            onClick={handleSendMessage}
                            disabled={sending}
                        >
                            <i className="fa-solid fa-paper-plane"></i>
                        </button>
                    </div>
                </div>
            </div>

            {/* Include AIModal component */}
            <AIModal messages={messages} show={showModal} handleClose={handleCloseModal} handleAiResponse={handleAiResponse} />
        </div>
    );
}

export default MessagesChat;
