import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../Translations/LenguageContext";
import { BiPencil } from "react-icons/bi";
import { FaLongArrowAltRight } from "react-icons/fa";

function CompleteRegistration() {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const user_id = sessionStorage.getItem("user_id");

    const [completedSteps, setCompletedSteps] = useState(0);
    const [totalSteps, setTotalSteps] = useState(2);

    useEffect(() => {
        const fetchProgress = async () => {
            try {
                const response = await fetch("https://boiling-mountain-85041-33258735eb3b.herokuapp.com/check_registration", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data.completed_steps !== undefined) {
                    setCompletedSteps(data.completed_steps);
                    setTotalSteps(data.total_steps);
                } else {
                    console.error(data.error);
                }
            } catch (error) {
                console.error("Error fetching registration progress:", error);
            }
        };

        fetchProgress();
    }, [user_id]);

    return (
        <div className="complete_registration_container">
            <div className="complete_reg_title">
                <p className="complete_reg_main_title">
                    <i><BiPencil /></i>{t.complete_account.title}
                </p>
                <p className="complete_reg_main_desc">
                    {t.complete_account.description}
                </p>
            </div>
            <div className="steps_container">
                <p className="steps_count">
                    {t.complete_account.completed_steps} <i><FaLongArrowAltRight /></i> {completedSteps}/{totalSteps}
                </p>
                <button onClick={() => navigate("/complete_reg")} className="complete_configuration_btn">
                    {t.complete_account.complete_btn}
                </button>
                {/* <button className="complete_later_configuration_btn">
                    {t.complete_account.not_now_btn}
                </button> */}
            </div>
        </div>
    );
}

export default CompleteRegistration;
