import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import MessagesOpenChats from "./MessagesOpenChats";
import MessagesChat from "./MessagesChat";

function Messages() {
    const { t } = useLanguage();
    const [userId, setUserId] = useState(sessionStorage.getItem("user_id"));
    const [selectedContact, setSelectedContact] = useState(null); // Selected contact
    const [messages, setMessages] = useState([]); // Messages for selected contact
    const [loading, setLoading] = useState(false); // To manage loading state
    const [error, setError] = useState(null); // To manage error state

    // Fetch messages when the selected contact or userId changes
    useEffect(() => {
        if (selectedContact && userId) {
            fetchMessages(selectedContact.email);
        }
    }, [selectedContact, userId]);

    const handleContactSelect = (contact) => {
        setSelectedContact(contact);
        // Fetch messages immediately upon selecting a contact
        fetchMessages(contact.email);
    };

    const fetchMessages = async (contactEmail) => {
        setLoading(true); // Start loading
        setError(null); // Reset error state
        try {
            const response = await fetch(`https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_messages/${contactEmail}?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setMessages(data.messages || []);
        } catch (error) {
            setError('Error fetching messages'); // Set error state
            console.error('Error fetching messages:', error);
        } finally {
            setLoading(false); // End loading
        }
    };

    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.sidebar.messages}</p>
            <div className="mex_main_view">
                <MessagesOpenChats userId={userId} onContactSelect={handleContactSelect} />
                <MessagesChat 
                    userId={userId} 
                    selectedContact={selectedContact} 
                    messages={messages} 
                    setMessages={setMessages}
                    loading={loading}
                    error={error}
                />
            </div>
        </div>
    );
}

export default Messages;
