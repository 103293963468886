import React, { useEffect } from "react";
import { useLanguage } from "../../Translations/LenguageContext";

const PopupMex = ({ active, type, onClose }) => {
    const { t } = useLanguage();
    const popupClass = type === "success" ? "popup_success" : type === "error" ? "popup_error" : "popup";

    useEffect(() => {
        if (active) {
            const timer = setTimeout(() => {
                const popup = document.getElementById(popupClass);
                popup.style.animation = "fadeOutUp 0.5s linear";
                setTimeout(() => {
                    if (onClose) onClose();
                }, 400);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [active, onClose, popupClass]);

    if (!active) {
        return null;
    }

    return (
        <div id={popupClass}>
            <p className="popup_mex">
                <i className={type === "success" ? "fa-solid fa-circle-check" : "fa-solid fa-circle-exclamation"}></i>
                {type === "success" ? t.popup.success : t.popup.error}
            </p>
            <div className="popup_loading_bar"></div>
        </div>
    );
};

export default PopupMex;
