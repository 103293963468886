import React from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { useNavigate } from "react-router-dom";
import { BiCircle, BiCheckCircle } from "react-icons/bi";

function StepsIndicator({ currentStep, setCurrentStep }) {
    const { t, changeLanguage, language } = useLanguage();
    const navigate = useNavigate();

    const steps = [
        { id: 1, label: t.complete_account.personal_info },
        { id: 2, label: t.complete_account.business_info },
        // { id: 3, label: t.complete_account.deposit_account }
    ];

    return (
        <div className="step_indicator_main">
            <div className="step_ind_cont">
                <p className="step_ind_title">
                    <i onClick={() => navigate("/control_panel/home")} className="fa-solid fa-chevron-left"></i>
                    {t.complete_account.title}
                </p>
                <div className="step_ind_conf_cont">
                    {steps.map((step, index) => (
                        <React.Fragment key={step.id}>
                            <div className="icon_title" onClick={() => setCurrentStep(step.id)}>
                                <i>
                                    {currentStep > step.id ? <BiCheckCircle /> : <BiCircle />}
                                </i>
                                <p>{step.label}</p>
                            </div>
                            {index < steps.length - 1 && <div className="connector"></div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default StepsIndicator;
