import React, { useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import { validators } from "tailwind-merge";

function PropertiesFilter({
    unit_type_value,
    title_property_value,
    nation_property_value,
    city_property_value,
    street_property_value,
    number_property_value,
    zip_property_value,
    type_property_value,
    mt2_property_value,
    floor_property_value,
    buildin_floors_property_value,
    energy_class_property_value,
    exposure_property_value,
    heating_property_value,
    external_fixtures_property_value,
    state_property_value,
    construction_year_property_value,
    living_property_value,
    bedroom_property_value,
    kitchen_property_value,
    bathroom_property_value,
    rent_price_property_value,
    currency_property_value,
    expenses_property_value,
    deposit_property_value,
    commissions_property_value,
    commission_type_property_value,
    other_details_value,
    onFilterChange
}) {
    const { t } = useLanguage();


    const TranslateOptions = (opt_value) => {
        switch (opt_value) {
            case "full_ownership":
                return t.properties.full_ownership;
            case "bare_ownership":
                return t.properties.bare_ownership;
            case "partial_ownership":
                return t.properties.partial_ownership;
            case "usufruct":
                return t.properties.usufruct;
            case "timeshare":
                return t.properties.timeshare;
            case "right_superficies":
                return t.properties.right_superficies;
            case "single_family":
                return t.properties.single_family;
            case "multi_family":
                return t.properties.multi_family;
            case "apartment":
                return t.properties.apartment;
            case "condominium":
                return t.properties.condominium;
            case "townhouse":
                return t.properties.townhouse;
            case "cooperative":
                return t.properties.cooperative;
            case "vacation_home":
                return t.properties.vacation_home;
            case "mobile_home":
                return t.properties.mobile_home;
            case "tiny_home":
                return t.properties.tiny_home;
            case "office_building":
                return t.properties.office_building;
            case "retail_property":
                return t.properties.retail_property;
            case "industrial_property":
                return t.properties.industrial_property;
            case "mixed_use":
                return t.properties.mixed_use;
            case "hotel":
                return t.properties.hotel;
            case "vacant_land":
                return t.properties.vacant_land;
            case "agricultural_land":
                return t.properties.agricultural_land;
            case "commercial_land":
                return t.properties.commercial_land;
            case "residential_land":
                return t.properties.residential_land;
            case "warehouse":
                return t.properties.warehouse;
            case "parking_lot":
                return t.properties.parking_lot;
            case "storage_facility":
                return t.properties.storage_facility;
            case "healthcare_facility":
                return t.properties.healthcare_facility;
            case "educational_facility":
                return t.properties.educational_facility;
            case "religious_facility":
                return t.properties.religious_facility;
            case "deposit_storage_area":
                return t.properties.deposit_storage_area;
            case "garage":
                return t.properties.garage;
            case "marina":
                return t.properties.marina;
            case "resort":
                return t.properties.resort;
            case "ranch":
                return t.properties.ranch;
            case "room":
                return t.properties.room;
            case "pending_certification":
                return t.properties.pending_certification;
            case "exempt_property":
                return t.properties.exempt_property;
            case "not_classifiable":
                return t.properties.not_classifiable;
            case "north":
                return t.properties.north;
            case "south":
                return t.properties.south;
            case "est":
                return t.properties.est;
            case "west":
                return t.properties.west;
            case "autonomous":
                return t.properties.autonomous;
            case "centralized":
                return t.properties.centralized;
            case "absent":
                return t.properties.absent;
            case "glass_wood":
                return t.properties.glass_wood;
            case "double_glass_wood":
                return t.properties.double_glass_wood;
            case "triple_glass_wood":
                return t.properties.triple_glass_wood;
            case "glass_metal":
                return t.properties.glass_metal;
            case "double_glass_metal":
                return t.properties.double_glass_metal;
            case "triple_glass_metal":
                return t.properties.triple_glass_metal;
            case "glass_pvc":
                return t.properties.glass_pvc;
            case "double_glass_pvc":
                return t.properties.double_glass_pvc;
            case "triple_glass_pvc":
                return t.properties.triple_glass_pvc;
            case "new_under_construction":
                return t.properties.new_under_construction;
            case "excellent_renovated":
                return t.properties.excellent_renovated;
            case "good_habitable":
                return t.properties.good_habitable;
            case "to_be_renovated":
                return t.properties.to_be_renovated;
            case "deposit":
                return t.properties.deposit;
            case "terrace":
                return t.properties.terrace;
            case "balcony":
                return t.properties.balcony;
            case "box_garage":
                return t.properties.box_garage;
            case "storage":
                return t.properties.storage;
            case "garden":
                return t.properties.garden;
            case "elevator":
                return t.properties.elevator;
            case "disabled_bathroom":
                return t.properties.disabled_bathroom;
            case "full_furnishing":
                return t.properties.full_furnishing;
            case "partial_furnishing":
                return t.properties.partial_furnishing;
            case "kitchen_furnishing":
                return t.properties.kitchen_furnishing;
            case "communal_garden":
                return t.properties.communal_garden;
            case "private_garden":
                return t.properties.private_garden;
            case "swimming_pool":
                return t.properties.swimming_pool;
            case "sauna":
                return t.properties.sauna;
            case "disabled_elevator":
                return t.properties.disabled_elevator;
            case "alarm_system":
                return t.properties.alarm_system;
            case "reception":
                return t.properties.reception;
            case "external_parking":
                return t.properties.external_parking;
            case "air_conditioning":
                return t.properties.air_conditioning;
            default:
                return opt_value; // Default to the type_property value if no match
        }
    };

    const initialFilters = {
        unit_type: '',
        title_property: '',
        nation_property: '',
        city_property: '',
        street_property: '',
        number_property: '',
        zip_property: '',
        type_property: '',
        mt2_property: '',
        floor_property: '',
        buildin_floors_property: '',
        energy_class_property: '',
        exposure_property: '',
        heating_property: '',
        external_fixtures_property: '',
        state_property: '',
        construction_year_property: '',
        living_property: '',
        bedroom_property: '',
        kitchen_property: '',
        bathroom_property: '',
        rent_price_property: '',
        currency_property: '',
        expenses_property: '',
        deposit_property: '',
        commissions_property: '',
        commission_type_property: '',
        other_details: [],
    };

    const [filters, setFilters] = useState(initialFilters);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: name === 'other_details' ? [value] : value
        }));
    };


    const handleFilterSubmit = () => {
        onFilterChange(filters);
    };

    const resetFilters = () => {
        setFilters(initialFilters);
        const selects = document.querySelectorAll(".prop_filter_select");
        selects.forEach(element => {
            element.value = "";
        });
        onFilterChange("");
    };

    const flattenedDetails = Array.isArray(other_details_value) ? other_details_value.flat() : [];


    return (
        <div className="property_filter">
            <div className="prop_filter_title_cont">
                <div className="prop_filter_title">
                    <i className="fa-solid fa-filter"></i>
                    <p>{t.properties.apply_filters}</p>
                </div>
            </div>
            <div className="prop_filetr_cont">
                <div className="prop_filter_title_input">
                    <label>{t.properties.title_main}</label>
                    <select className="prop_filter_select" name="title_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {title_property_value.map((title_property, index) => (
                            <option key={index} value={title_property}>
                                {title_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.unit_type}</label>
                    <select className="prop_filter_select" name="unit_type" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {unit_type_value.map((unit_type, index) => (
                            <option key={index} value={unit_type}>
                                {TranslateOptions(unit_type)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.nation}</label>
                    <select className="prop_filter_select" name="nation_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {nation_property_value.map((nation_property, index) => (
                            <option key={index} value={nation_property}>
                                {nation_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.city}</label>
                    <select className="prop_filter_select" name="city_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {city_property_value.map((city_property, index) => (
                            <option key={index} value={city_property}>
                                {city_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="multi_input_cont">
                    <div className="prop_filter_title_input first">
                        <label>{t.properties.street_num}</label>
                        <select className="prop_filter_select" name="street_property" onChange={handleFilterChange}>
                            <option value="">-</option>
                            {street_property_value.map((street_property, index) => (
                                <option key={index} value={street_property}>
                                    {street_property}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="prop_filter_title_input second">
                        <label>N.</label>
                        <select className="prop_filter_select" name="number_property" onChange={handleFilterChange}>
                            <option value="">-</option>
                            {number_property_value.map((number_property, index) => (
                                <option key={index} value={number_property}>
                                    {number_property}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.zip}</label>
                    <select className="prop_filter_select" name="zip_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {zip_property_value.map((zip_property, index) => (
                            <option key={index} value={zip_property}>
                                {zip_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.prop_type}</label>
                    <select className="prop_filter_select" name="type_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {type_property_value.map((type_property, index) => (
                            <option key={index} value={type_property}>
                                {TranslateOptions(type_property)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.mt2}</label>
                    <select className="prop_filter_select" name="mt2_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {mt2_property_value.map((mt2_property, index) => (
                            <option key={index} value={mt2_property}>
                                {mt2_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.floor}</label>
                    <select className="prop_filter_select" name="floor_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {floor_property_value.map((floor_property, index) => (
                            <option key={index} value={floor_property}>
                                {floor_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.building_floors}</label>
                    <select className="prop_filter_select" name="buildin_floors_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {buildin_floors_property_value.map((buildin_floors_property, index) => (
                            <option key={index} value={buildin_floors_property}>
                                {buildin_floors_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.energy_class}</label>
                    <select className="prop_filter_select" name="energy_class_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {energy_class_property_value.map((energy_class_property, index) => (
                            <option key={index} value={energy_class_property}>
                                {TranslateOptions(energy_class_property)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.exposure}</label>
                    <select className="prop_filter_select" name="exposure_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {exposure_property_value.map((exposure_property, index) => (
                            <option key={index} value={exposure_property}>
                                {TranslateOptions(exposure_property)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.heating}</label>
                    <select className="prop_filter_select" name="heating_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {heating_property_value.map((heating_property, index) => (
                            <option key={index} value={heating_property}>
                                {TranslateOptions(heating_property)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.external_fixtures}</label>
                    <select className="prop_filter_select" name="external_fixtures_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {external_fixtures_property_value.map((external_fixtures_property, index) => (
                            <option key={index} value={external_fixtures_property}>
                                {TranslateOptions(external_fixtures_property)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.prop_state}</label>
                    <select className="prop_filter_select" name="state_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {state_property_value.map((state_property, index) => (
                            <option key={index} value={state_property}>
                                {TranslateOptions(state_property)}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.construction_year}</label>
                    <select className="prop_filter_select" name="construction_year_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {construction_year_property_value.map((construction_year_property, index) => (
                            <option key={index} value={construction_year_property}>
                                {construction_year_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.living}</label>
                    <select className="prop_filter_select" name="living_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {living_property_value.filter(living_property => living_property !== null).map((living_property, index) => (
                            <option key={index} value={living_property}>
                                {living_property}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="prop_filter_title_input">
                    <label>{t.properties.bedroom}</label>
                    <select className="prop_filter_select" name="bedroom_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {bedroom_property_value.filter(bedroom_property => bedroom_property !== null).map((bedroom_property, index) => (
                            <option key={index} value={bedroom_property}>
                                {bedroom_property}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="prop_filter_title_input">
                    <label>{t.properties.kitchen}</label>
                    <select className="prop_filter_select" name="kitchen_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {kitchen_property_value.filter(kitchen_property => kitchen_property !== null).map((kitchen_property, index) => (
                            <option key={index} value={kitchen_property}>
                                {kitchen_property}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="prop_filter_title_input">
                    <label>{t.properties.bathroom}</label>
                    <select className="prop_filter_select" name="bathroom_property" onChange={handleFilterChange}>
                        <option value="">-</option>
                        {bathroom_property_value.filter(bathroom_property => bathroom_property !== null).map((bathroom_property, index) => (
                            <option key={index} value={bathroom_property}>
                                {bathroom_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="multi_input_cont">
                    <div className="prop_filter_title_input first">
                        <label>{t.properties.rent_price}</label>
                        <select className="prop_filter_select" name="rent_price_property" onChange={handleFilterChange}>
                            <option>-</option>
                            {rent_price_property_value.map((rent_price_property, index) => (
                                <option key={index} value={rent_price_property}>
                                    {rent_price_property}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="prop_filter_title_input second">
                        <label>{t.properties.currency}</label>
                        <select className="prop_filter_select" name="currency_property" onChange={handleFilterChange}>
                            <option>-</option>
                            {currency_property_value.map((currency_property, index) => (
                                <option key={index} value={currency_property}>
                                    {currency_property}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.expenses}</label>
                    <select className="prop_filter_select" name="expenses_property" onChange={handleFilterChange}>
                        <option>-</option>
                        {expenses_property_value.map((expenses_property, index) => (
                            <option key={index} value={expenses_property}>
                                {expenses_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.deposit}</label>
                    <select className="prop_filter_select" name="deposit_property" onChange={handleFilterChange}>
                        <option>-</option>
                        {deposit_property_value.map((deposit_property, index) => (
                            <option key={index} value={deposit_property}>
                                {deposit_property}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="multi_input_cont">
                    <div className="prop_filter_title_input first">
                        <label>{t.properties.commissions}</label>
                        <select className="prop_filter_select" name="commissions_property" onChange={handleFilterChange}>
                            <option>-</option>
                            {commissions_property_value.map((commissions_property, index) => (
                                <option key={index} value={commissions_property}>
                                    {commissions_property}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="prop_filter_title_input second">
                        <label>{t.properties.comm_type}</label>
                        <select className="prop_filter_select" name="commission_type_property" onChange={handleFilterChange}>
                            <option>-</option>
                            {commission_type_property_value.map(
                                (commission_type_property, index) => (
                                    <option key={index} value={commission_type_property}>
                                        {commission_type_property}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                </div>
                <div className="prop_filter_title_input">
                    <label>{t.properties.other_included_details}</label>
                    <select className="prop_filter_select" name="other_details" onChange={handleFilterChange}>
                        <option>-</option>
                        {flattenedDetails.map((detail, index) => (
                            <option key={index} value={detail}>
                                {TranslateOptions(detail)}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="prop_filter_btn_cont">
                    <button onClick={handleFilterSubmit} className="filter_btn">{t.properties.filter_btn}<i className="fa-solid fa-arrow-right"></i></button>
                    <button className="filter_btn" onClick={resetFilters}><i className="fa-solid fa-arrows-rotate"></i></button>
                </div>
            </div>
        </div>
    );
}

export default PropertiesFilter;