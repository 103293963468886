import React, {useState} from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import WBModal from "./WB_Modal";

function WBManager({onWebsiteAdded}){
    const { t } = useLanguage();
    const [showModal, setShowModal] = useState(false);
    const [selectedWebsite, setSelectedWebsite] = useState(null);

    const handleShow = () => {
        setShowModal(true)
        setSelectedWebsite(false);
    };

    const handleClose = () => setShowModal(false);

    return(
        <div className="main_view_section">
            <p className="title_main_view">Website Builder</p>
            <div className="wb_builder_main">
                <button className="create_new_website_btn" onClick={handleShow}><i className="fa-solid fa-plus"></i> Crea nuovo sito</button>
                <div className="website_cards_container">
                    <div className="website_card">
                        
                    </div>
                </div>

            </div>
            <WBModal  show={showModal} handleClose={handleClose} onWebsiteAdded={onWebsiteAdded} websiteData={selectedWebsite} ></WBModal>
        </div>
    )
}

export default WBManager