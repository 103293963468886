import React from "react";

function Notifications() {
    return (
        <div className="main_view_section">
            <p className="title_main_view">Notifications</p>
        </div>
    )
}

export default Notifications