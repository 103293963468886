// App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { LanguageProvider } from './Translations/LenguageContext';
import './Mobile.css';
import './App.css';


//Website
import Homepage from './Website/Homepage';
import AboutUs from './Website/AboutUs';
import OpenPositions from './Website/OpenPositions';
import Blog from './Website/Blog';
import TermsConditions from './Website/TermsConditions';
import Privacy from './Website/Privacy';
import Cookies from './Website/Cookies';
import LegalNotes from './Website/LegalNotes';
import FlatJourneyAI from './Website/FlatJourneyAI';
import FlatJourneyPMS from './Website/FlatJourneyPMS';
import FlatPay from './Website/FlatPay';

import Login from './App/Login';
import Register from './App/Register';
import TLL from './App/Tenant_landlord';
import ControlPanel from './App/ControlPanel';
import WelcomeMessage from './App/WelcomeMessage';
import MainStepsCont from './App/LLSections/CompleteRegistration/MainStepsContainer';
import RegistrationComplete from './App/LLSections/CompleteRegistration/RegistrationComplete';
import PaymentsCompletedStripe from './App/LLSections/Payments/PaymentsCompleteStripe';

const App = () => {
  return (
    <LanguageProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/open_positions" element={<OpenPositions />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/terms_conditions" element={<TermsConditions />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/legal_notes" element={<LegalNotes />} />
        <Route path="/flatjourneyAI" element={<FlatJourneyAI />} />
        <Route path="/flatjourneyPMS" element={<FlatJourneyPMS />} />
        <Route path="/flatPay" element={<FlatPay />} />

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/tll" element={<TLL />} />
        <Route path="/welcome" element={<WelcomeMessage />} />
        <Route path="/control_panel/*" element={<ControlPanel />} />
        <Route path="/complete_reg" element={<MainStepsCont />} />
        <Route path="/competed_registration" element={<RegistrationComplete />} />
        <Route path="/competed_stipe_setup" element={<PaymentsCompletedStripe />} />
      </Routes>
    </Router>
    </LanguageProvider>
  );
};

export default App;
