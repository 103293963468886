import React from "react";
import { useLanguage } from "../../Translations/LenguageContext"; 

function ExpensesForecast() {
    const { t, changeLanguage, language } = useLanguage();
    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.sidebar.expenses_forecast}</p>
        </div>
    )
}

export default ExpensesForecast