import React from "react";
import Navbar from "./Navbar";

function AboutUs(){
    
    return(
        <div>
            <Navbar></Navbar>
            <div className="about_us_container">

                
            </div>
        </div>
    )
}

export default AboutUs