import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Spinner from "../../../components/Spinner";
import { useLanguage } from '../../../Translations/LenguageContext';


const WBModal = ({ show, handleClose, onWebsiteAdded, websiteData }) => {
    const { t } = useLanguage();
    const navigate = useNavigate();

    const NavigateToBuilder = () =>{
        navigate(`/control_panel`);
    }

    return (
        <Modal show={show} onHide={handleClose} className="modal_booking">
            <div className='footer_modal'>
                <div className='footer_btn_cont'>
                    <button className='save_modal_btn' onClick={NavigateToBuilder}>
                        Salva
                    </button>
                    <button className='close_modal_btn' onClick={handleClose}>
                        Chiudi
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default WBModal;
