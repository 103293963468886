import React, { useState } from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import AddBookingModal from "./AddBookingModal";

const bookingTypeColors = {
    personal: "darkOrange",
    meeting: "#7b85c8",
    visit: "#424fb1",
    second_visit: "#424fb1",
    contract_sign: "#1b8047",
    work: "#d10e0e",
    short_term: "var(--secondColor)",
    long_term: "var(--mainColor)",
    other: "var(--azure)"
};

function BookingSide({ bookings, onBookingAdded }) {
    const [currentDate, setCurrentDate] = useState(new Date());
    const { t } = useLanguage();
    const [showModal, setShowModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);


    const handleShow = () => {
        setShowModal(true)
        setSelectedBooking(false);
    };
    const handleClose = () => setShowModal(false);

    const handleCellClick = (booking) => {
        setSelectedBooking(booking);
        setShowModal(true);
    };


    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        return capitalizeFirstLetter(new Intl.DateTimeFormat(t.booking.date_leng, options).format(date));
    };

    const handlePrevDay = () => {
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() - 1);
            return newDate;
        });
    };

    const handleNextDay = () => {
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() + 1);
            return newDate;
        });
    };

    const getDateRange = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        let dates = [];
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            dates.push(new Date(d).toISOString().split("T")[0]);
        }
        return dates;
    };

    const bookingsArray = Array.isArray(bookings) ? bookings : Object.values(bookings || {});

    const filteredBookings = bookingsArray.flatMap(booking => {
        const startDate = booking.start_date ? booking.start_date.split('/').reverse().join('-') : '';
        const endDate = booking.end_date ? booking.end_date.split('/').reverse().join('-') : '';
        const dateRange = getDateRange(startDate, endDate);
        return dateRange.map(date => ({
            ...booking,
            date,
        }));
    }).filter(booking => booking.date === currentDate.toISOString().split("T")[0]);

    const allDayBookings = filteredBookings.filter(booking => booking.start_time === "00:00" && booking.end_time === "23:45");
    const otherBookings = filteredBookings.filter(booking => !(booking.start_time === "00:00" && booking.end_time === "23:45"));

    const sortedOtherBookings = otherBookings.sort((a, b) => {
        const startHourA = a.start_time || '';
        const startHourB = b.start_time || '';
        return startHourA.localeCompare(startHourB);
    });

    // Filter out bookings with types 'short_term' and 'long_term'
    const combinedBookings = [...allDayBookings, ...sortedOtherBookings].filter(booking =>
        booking.booking_type !== "short_term" && booking.booking_type !== "long_term"
    );

    const groupedBookings = combinedBookings.reduce((acc, booking) => {
        const startHour = booking.start_time || 'unknown';
        if (!acc[startHour]) {
            acc[startHour] = [];
        }
        acc[startHour].push(booking);
        return acc;
    }, {});

    return (
        <div className='side_bookings_main'>
            <div className="side_booking_day_date">
                <button onClick={handlePrevDay} className="navigate_cal_btn"><i className="fa-solid fa-chevron-left"></i></button>
                <div className="day_date">
                    {formatDate(currentDate)}
                </div>
                <button onClick={handleNextDay} className="navigate_cal_btn"><i className="fa-solid fa-chevron-right"></i></button>
            </div>
            <div className="side_booking_body">
                {combinedBookings.length === 0 ? (
                    <div className='no_data_mex_cont'>
                        <p className='no_data_mex'>{t.booking.no_bookings}</p>
                        {/* <div className='no_data_img'></div> */}
                    </div>
                ) : (
                    Object.keys(groupedBookings).map(hour => (
                        <div key={hour} className="hour_group">
                            <div className="hour_label">
                                {hour === "00:00" && groupedBookings[hour][0]?.end_time === "23:45"
                                    ? "All day"
                                    : `${hour} - ${groupedBookings[hour][0]?.end_time || 'unknown'}`}
                            </div>
                            {groupedBookings[hour].map((booking, index) => (
                                <div
                                    onClick={() => handleCellClick(booking)}
                                    key={index}
                                    className="hour_booking"
                                    style={{
                                        backgroundColor: bookingTypeColors[booking.booking_type] || "var(--mainColor)",
                                        borderLeft: "5px solid var(--mainColor)" || "var(--mainColor)",
                                        border: `1px solid ${bookingTypeColors[booking.booking_type] || "var(--mainColor)"}`,
                                    }}
                                >
                                    <p className="title_hour_booking">
                                        {booking.title}
                                    </p>
                                    <div className="hour_hour_booking">

                                        {booking.start_time === "00:00" && booking.end_time === "23:45"
                                            ? "All day"
                                            : `${booking.start_time || 'unknown'} - ${booking.end_time || 'unknown'}`}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))
                )}
            </div>
            <AddBookingModal onBookingAdded={onBookingAdded} show={showModal} handleClose={handleClose} booking={selectedBooking} />
        </div>
    );
}

export default BookingSide;
