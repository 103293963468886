import React, { useState } from 'react';
import { useLanguage } from "../../../Translations/LenguageContext";
import { CgCalendarToday } from "react-icons/cg";
import { BiCalendarCheck } from "react-icons/bi";
import AddBookingModal from './AddBookingModal';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const generateDates = (startDate, numDays) => {
    const dates = [];
    const currentDate = new Date(startDate);

    for (let i = 0; i < numDays; i++) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
};

const parseDate = (dateStr) => new Date(dateStr);

const adjustToMonday = (date) => {
    const day = date.getDay();
    const diff = (day === 0 ? -6 : 1 - day);
    date.setDate(date.getDate() + diff);
    return date;
};

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const bookingTypeColors = {
    personal: "darkOrange",
    meeting: "#7b85c8",
    visit: "#424fb1",
    second_visit: "#424fb1",
    contract_sign: "#1b8047",
    work: "#d10e0e",
    short_term: "var(--secondColor)",
    long_term: "var(--mainColor)",
    other: "var(--azure)"
};

const BookingMainCal = ({ bookings, onBookingAdded }) => {
    const { t } = useLanguage();
    const [startDate, setStartDate] = useState(adjustToMonday(new Date()));
    const numDays = 7;
    const [showModal, setShowModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);

    const handleShow = () => {
        setShowModal(true);
        setSelectedBooking(false);
    };
    const handleClose = () => setShowModal(false);

    const updateDates = () => {
        const dates = generateDates(startDate, numDays);
        return { dates, calendarStartDate: dates[0], calendarEndDate: dates[dates.length - 1] };
    };

    const { dates, calendarStartDate, calendarEndDate } = updateDates();

    const shiftDates = (days) => {
        const newStartDate = new Date(startDate);
        newStartDate.setDate(newStartDate.getDate() + days);
        setStartDate(adjustToMonday(newStartDate));
    };

    const handleTodayClick = () => {
        setStartDate(adjustToMonday(new Date()));
    };

    const isToday = (date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const formatDateWithDay = (date) => {
        const options = { weekday: 'short', day: 'numeric' };
        return capitalizeFirstLetter(new Intl.DateTimeFormat(t.booking.date_leng, options).format(date));
    };

    const getMonthName = (date) => {
        const options = { month: 'long', year: 'numeric' };
        return capitalizeFirstLetter(new Intl.DateTimeFormat(t.booking.date_leng, options).format(date));
    };

    const bookingsArray = Array.isArray(bookings) ? bookings : Object.values(bookings || {});

    const getBookingDetails = (date, hour, quarter) => {
        const booking = bookingsArray.find(booking => {
            const bookingStart = parseDate(booking.start_date);
            const bookingEnd = parseDate(booking.end_date);
            const startHour = new Date(`${booking.start_date}T${booking.start_time}`);
            const endHour = new Date(`${booking.end_date}T${booking.end_time}`);

            return date.toDateString() === bookingStart.toDateString() &&
                (startHour.getHours() <= hour && endHour.getHours() >= hour);
        });

        if (!booking) return null;

        const startHour = new Date(`${booking.start_date}T${booking.start_time}`);
        const endHour = new Date(`${booking.end_date}T${booking.end_time}`);
        const startMinutes = startHour.getMinutes();
        const endMinutes = endHour.getMinutes();

        const bookingTypeColor = bookingTypeColors[booking.booking_type] || 'transparent';

        // Use the same logic for determining the correct quarter to display the booking details

        // For the start of the booking
        if (date.toDateString() === startHour.toDateString() && hour === startHour.getHours()) {
            if ((startMinutes === 15 && quarter === 1) ||
                (startMinutes === 30 && quarter === 2) ||
                (startMinutes === 45 && quarter === 3) ||
                (startMinutes === 0 && quarter === 0)) {
                return {
                    title: booking.title,
                    startTime: booking.start_time,
                    endTime: booking.end_time,
                    color: bookingTypeColor,
                    booking // Return the booking object here
                };
            }
        }

        return null;
    };



    const getBookingColor = (date, hour, quarter) => {
        const booking = bookingsArray.find(booking => {
            const bookingStart = parseDate(booking.start_date);
            const bookingEnd = parseDate(booking.end_date);
            const startHour = new Date(`${booking.start_date}T${booking.start_time}`);
            const endHour = new Date(`${booking.end_date}T${booking.end_time}`);

            return date.toDateString() === bookingStart.toDateString() &&
                (startHour.getHours() <= hour && endHour.getHours() >= hour);
        });

        if (!booking) return 'transparent';

        const startHour = new Date(`${booking.start_date}T${booking.start_time}`);
        const endHour = new Date(`${booking.end_date}T${booking.end_time}`);
        const bookingTypeColor = bookingTypeColors[booking.booking_type] || 'transparent';

        const quarterStartMinute = quarter * 15;
        const quarterEndMinute = (quarter + 1) * 15;

        const startMinutes = startHour.getMinutes();
        const endMinutes = endHour.getMinutes();

        if (date.toDateString() === startHour.toDateString() && hour === startHour.getHours()) {
            if ((startMinutes === 15 && quarter === 1) ||
                (startMinutes === 30 && quarter === 2) ||
                (startMinutes === 45 && quarter === 3) ||
                (startMinutes === 0 && quarter === 0)) {
                return bookingTypeColor;
            }
        }

        if (date.toDateString() === endHour.toDateString() && hour === endHour.getHours()) {
            if ((endMinutes === 15 && quarter === 0) ||
                (endMinutes === 30 && quarter === 1) ||
                (endMinutes === 45 && quarter === 2)) {
                return bookingTypeColor;
            }
        }

        if ((startHour.getHours() < hour || (startHour.getHours() === hour && startMinutes < quarterStartMinute)) &&
            (endHour.getHours() > hour || (endHour.getHours() === hour && endMinutes > quarterEndMinute))) {
            return bookingTypeColor;
        }

        return 'transparent';
    };

    const handleCellClick = (booking) => {
        setSelectedBooking(booking);
        setShowModal(true);
    };

    return (
        <div className="bookings_cal_main">
            <div className="booking_function_bar">
                <div className='boocking_func_cont'>
                    <button className='add_new_booking_btn' onClick={handleShow}>
                        <i className='fa-solid fa-plus'></i> {t.booking.add_booking}
                    </button>
                    <button className='booking_func_btns' onClick={handleTodayClick}>
                        <CgCalendarToday />
                    </button>
                </div>
                <div className='boocking_func_cont_search'>
                    <button className='navigate_cal_btn' onClick={() => shiftDates(numDays)}>
                        <i className='fa-solid fa-chevron-right'></i>
                    </button>
                    <button className='navigate_cal_btn' onClick={() => shiftDates(-numDays)}>
                        <i className='fa-solid fa-chevron-left'></i>
                    </button>
                </div>
            </div>
            <div className='month_booking_cal'>
                {getMonthName(startDate)}
            </div>
            <div className="booking_calendar">
                <div className="calendar-container">
                    <div className="calendar-header">
                        {dates.map(date => (
                            <div
                                key={date.toISOString()}
                                className={`calendar-date ${isToday(date) ? 'today-date' : ''}`}
                            >
                                {formatDateWithDay(date)}
                            </div>
                        ))}
                    </div>
                    <div className="calendar-rows">
                        {Array.from({ length: 24 }).map((_, hourIndex) => (
                            <div className="calendar-hour-row" key={hourIndex}>
                                {dates.map((date, dateIndex) => (
                                    <div key={dateIndex} className="calendar-hour-cell">
                                        {Array.from({ length: 4 }).map((_, quarterIndex) => {
                                            const bookingDetails = getBookingDetails(date, hourIndex, quarterIndex);

                                            const startMinute = quarterIndex * 15;
                                            let endMinute = startMinute + 15;
                                            let endHour = hourIndex;

                                            if (endMinute === 60) {
                                                endMinute = 0;
                                                endHour += 1;
                                            }

                                            if (endHour === 24) {
                                                endHour = 0;
                                            }

                                            const startTime = `${hourIndex.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`;
                                            const endTime = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;
                                            const formatedDate = date.toISOString().split('T')[0]
                                            return (
                                                <div
                                                    key={quarterIndex}
                                                    className="calendar-quarter-cell"
                                                    style={{ backgroundColor: getBookingColor(date, hourIndex, quarterIndex) }}
                                                    onClick={() => {
                                                        const bookingDetails = getBookingDetails(date, hourIndex, quarterIndex);
                                                        if (bookingDetails) {
                                                            handleCellClick(bookingDetails.booking);
                                                            console.log(bookingDetails.booking)
                                                        } else {
                                                            let book = {
                                                                booking_type: null,
                                                                description: null,
                                                                end_date: formatedDate,
                                                                end_time: endTime,
                                                                invited: [],
                                                                key: null,
                                                                position: null,
                                                                start_date: formatedDate,
                                                                start_time: startTime,
                                                                title: null
                                                            }
                                                            handleCellClick(book);
                                                        }
                                                    }}
                                                >
                                                    {!bookingDetails && (
                                                        <div className="quarter-cell-time">{`${startTime} - ${endTime}`}</div>
                                                    )}
                                                    {bookingDetails && (
                                                        <div className="booking-info">
                                                            <p className='booking_title'>{bookingDetails.title}</p>
                                                            <p className='booking_hour'>{bookingDetails.startTime} - {bookingDetails.endTime}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>


                </div>
            </div>
            <AddBookingModal onBookingAdded={onBookingAdded} show={showModal} handleClose={handleClose} booking={selectedBooking} />
        </div>
    );
}

export default BookingMainCal;
