import React from "react";
import { useLanguage } from "../../../Translations/LenguageContext";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { RiSecurePaymentFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const Tenants = () => {
    const { t } = useLanguage()
    const navigate = useNavigate()

    const AddNewUserClick = () =>{
        navigate("/control_panel/tenants/new-tenant")
    }

    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.tenants.tenants}</p>
            <div className="tenants_view">
                <div className="tenants_graphs">
                    <div className="charts_cont">
                        <p className="charts_title"><i><RiSecurePaymentFill/></i> Canoni d'affitto</p>
                        <Stack className="stack_chart" direction="row" sx={{ width: '100%' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <SparkLineChart
                                    colors={['var(--mainColor)']}
                                    data={[1, 4, 2, 5, 7, 2, 4, 6]}
                                    xAxis={{
                                        scaleType: 'time',
                                        data: [
                                            new Date(2022, 5, 1),
                                            new Date(2022, 5, 2),
                                            new Date(2022, 5, 5),
                                            new Date(2022, 5, 6),
                                            new Date(2022, 5, 7),
                                            new Date(2022, 5, 8),
                                            new Date(2022, 5, 11),
                                            new Date(2022, 5, 12),
                                        ],
                                        valueFormatter: (value) => value.toISOString().slice(0, 10),

                                    }}
                                    height={100}
                                    showTooltip
                                    showHighlight
                                />
                            </Box>
                        </Stack>
                    </div>
                    <div className="charts_cont">
                        <p className="charts_title"><i><RiSecurePaymentFill/></i> Incassi spese</p>
                        <Stack className="stack_chart" direction="row" sx={{ width: '100%' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <SparkLineChart
                                    colors={['var(--secondColor)']}
                                    data={[1, 4, 2, 5, 7, 2, 4, 6]}
                                    xAxis={{
                                        scaleType: 'time',
                                        data: [
                                            new Date(2022, 5, 1),
                                            new Date(2022, 5, 2),
                                            new Date(2022, 5, 5),
                                            new Date(2022, 5, 6),
                                            new Date(2022, 5, 7),
                                            new Date(2022, 5, 8),
                                            new Date(2022, 5, 11),
                                            new Date(2022, 5, 12),
                                        ],
                                        valueFormatter: (value) => value.toISOString().slice(0, 10),

                                    }}
                                    height={100}
                                    showTooltip
                                    showHighlight
                                />
                            </Box>
                        </Stack>
                    </div>
                    <div className="charts_cont">
                        <p className="charts_title"><i><RiSecurePaymentFill/></i> Depositi cauzionali</p>
                        <Stack className="stack_chart" direction="row" sx={{ width: '100%' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <SparkLineChart
                                    colors={['var(--azure)']}
                                    data={[1, 4, 2, 5, 7, 2, 4, 6]}
                                    xAxis={{
                                        scaleType: 'time',
                                        data: [
                                            new Date(2022, 5, 1),
                                            new Date(2022, 5, 2),
                                            new Date(2022, 5, 5),
                                            new Date(2022, 5, 6),
                                            new Date(2022, 5, 7),
                                            new Date(2022, 5, 8),
                                            new Date(2022, 5, 11),
                                            new Date(2022, 5, 12),
                                        ],
                                        valueFormatter: (value) => value.toISOString().slice(0, 10),

                                    }}
                                    height={100}
                                    showTooltip
                                    showHighlight
                                />
                            </Box>
                        </Stack>
                    </div>
                </div>
                <div className="tenants_filter_cont">
                    <button className="add_property_btn" onClick={AddNewUserClick}><i className="fa-solid fa-plus"></i> Add tenant</button>
                    <div className="tenant_filters">
                        <button>To pay</button>
                        <button>Payed</button>
                        <select className="property_select_tenant_filter">
                            <option>-</option>
                        </select>
                    </div>
                </div>
                <div className="tenants_table_cont">

                </div>
            </div>
        </div>
    )
}

export default Tenants