import React, { useEffect, useState } from 'react';
import { useLanguage } from "../../../Translations/LenguageContext";
import AddPropertieModal from "./AddPropertieModal";
import PopupMex from '../SavePopup';
import PropertiesFilter from './PropertiesFilter';
import PropertiesMain from './PropertiesMain';

function Properties() {
    const { t } = useLanguage();
    const [showModal, setShowModal] = useState(false);
    const [properties, setProperties] = useState({});
    const [popupActive, setPopupActive] = useState(false);
    const [popupType, setPopupType] = useState("success");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filters, setFilters] = useState({});

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const userId = sessionStorage.getItem("user_id");

    // Fetch properties from the server
    const fetchProperties = async () => {
        try {
            const response = await fetch('https://boiling-mountain-85041-33258735eb3b.herokuapp.com/get_properties', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProperties(data.properties || {});
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    const handlePropertyAdded = () => {
        fetchProperties();
        setPopupType("success");
        setPopupActive(true);
    };

    const handlePopupClose = () => {
        setPopupActive(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };

    // Extract unique values for dropdowns
    const extractUniqueValues = (key) => {
        const values = Object.values(properties).flatMap(property => property[key] || []);
        const uniqueValues = new Set(values);
        return [...uniqueValues];
    };    

    const unit_type = extractUniqueValues('unit_type');
    const title_property = extractUniqueValues('title_property');
    const nation_property = extractUniqueValues('nation_property');
    const city_property = extractUniqueValues('city_property');
    const street_property = extractUniqueValues('street_property');
    const number_property = extractUniqueValues('number_property');
    const zip_property = extractUniqueValues('zip_property');
    const type_property = extractUniqueValues('type_property');
    const mt2_property = extractUniqueValues('mt2_property');
    const floor_property = extractUniqueValues('floor_property');
    const buildin_floors_property = extractUniqueValues('buildin_floors_property');
    const energy_class_property = extractUniqueValues('energy_class_property');
    const exposure_property = extractUniqueValues('exposure_property');
    const heating_property = extractUniqueValues('heating_property');
    const external_fixtures_property = extractUniqueValues('external_fixtures_property');
    const state_property = extractUniqueValues('state_property');
    const construction_year_property = extractUniqueValues('construction_year_property');
    const living_property = extractUniqueValues('living_property');
    const bedroom_property = extractUniqueValues('bedroom_property');
    const kitchen_property = extractUniqueValues('kitchen_property');
    const bathroom_property = extractUniqueValues('bathroom_property');
    const rent_price_property = extractUniqueValues('rent_price_property');
    const currency_property = extractUniqueValues('currency_property');
    const expenses_property = extractUniqueValues('expenses_property');
    const deposit_property = extractUniqueValues('deposit_property');
    const commissions_property = extractUniqueValues('commissions_property');
    const commission_type_property = extractUniqueValues('commission_type_property');
    const other_details = extractUniqueValues('other_details');

    // Filtered properties
    const filteredProperties = Object.entries(properties).filter(([key, property]) => {
        const searchLowerCase = searchQuery.toLowerCase();
        const getValue = (value) => (value ? value.toLowerCase() : '');

        const rentAndExpensesTotal = (
            (Number(property.rent_price_property) || 0) +
            (Number(property.expenses_property) || 0)
        ).toFixed(2);

        // Search filter
        const matchesSearchQuery = (
            getValue(property.title_property).includes(searchLowerCase) ||
            getValue(property.unit_type).includes(searchLowerCase) ||
            getValue(property.nation_property).includes(searchLowerCase) ||
            getValue(property.city_property).includes(searchLowerCase) ||
            getValue(property.street_property).includes(searchLowerCase) ||
            getValue(property.number_property).includes(searchLowerCase) ||
            getValue(property.zip_property).includes(searchLowerCase) ||
            getValue(property.currency_property).includes(searchLowerCase) ||
            getValue(property.mt2_property).includes(searchLowerCase) ||
            getValue(property.living_property).includes(searchLowerCase) ||
            getValue(property.bedroom_property).includes(searchLowerCase) ||
            getValue(property.kitchen_property).includes(searchLowerCase) ||
            getValue(property.bathroom_property).includes(searchLowerCase) ||
            rentAndExpensesTotal.includes(searchLowerCase)
        );

        // Filters
        const matchesFilters = (
            (!filters.unit_type || property.unit_type === filters.unit_type) &&
            (!filters.title_property || property.title_property === filters.title_property) &&
            (!filters.nation_property || property.nation_property === filters.nation_property) &&
            (!filters.city_property || property.city_property === filters.city_property) &&
            (!filters.street_property || property.street_property === filters.street_property) &&
            (!filters.number_property || property.number_property === filters.number_property) &&
            (!filters.zip_property || property.zip_property === filters.zip_property) &&
            (!filters.type_property || property.type_property === filters.type_property) &&
            (!filters.mt2_property || property.mt2_property === filters.mt2_property) &&
            (!filters.floor_property || property.floor_property === filters.floor_property) &&
            (!filters.buildin_floors_property || property.buildin_floors_property === filters.buildin_floors_property) &&
            (!filters.energy_class_property || property.energy_class_property === filters.energy_class_property) &&
            (!filters.exposure_property || property.exposure_property === filters.exposure_property) &&
            (!filters.heating_property || property.heating_property === filters.heating_property) &&
            (!filters.external_fixtures_property || property.external_fixtures_property === filters.external_fixtures_property) &&
            (!filters.state_property || property.state_property === filters.state_property) &&
            (!filters.construction_year_property || property.construction_year_property === filters.construction_year_property) &&
            (!filters.living_property || property.living_property === filters.living_property) &&
            (!filters.bedroom_property || property.bedroom_property === filters.bedroom_property) &&
            (!filters.kitchen_property || property.kitchen_property === filters.kitchen_property) &&
            (!filters.bathroom_property || property.bathroom_property === filters.bathroom_property) &&
            (!filters.rent_price_property || property.rent_price_property === filters.rent_price_property) &&
            (!filters.currency_property || property.currency_property === filters.currency_property) &&
            (!filters.expenses_property || property.expenses_property === filters.expenses_property) &&
            (!filters.deposit_property || property.deposit_property === filters.deposit_property) &&
            (!filters.commissions_property || property.commissions_property === filters.commissions_property) &&
            (!filters.commission_type_property || property.commission_type_property === filters.commission_type_property) &&
            (!filters.other_details || filters.other_details.length === 0 || property.other_details && filters.other_details.some(detail => property.other_details.includes(detail)))
        );

        return matchesSearchQuery && matchesFilters;
    });

    const handlePropertyDeleted = (deletedPropertyKey) => {
        setProperties(prevProperties => {
            const updatedProperties = Object.entries(prevProperties).filter(([key]) => key !== deletedPropertyKey);
            return Object.fromEntries(updatedProperties);
        });
    };

    if (loading) return (
        <div className='loading_mex_cont'>
            <p className='loading_mex'>{t.loading}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );
    
    if (error) return (
        <div className='loading_mex_cont_err'>
            <p>{t.error}</p>
            <i className='fa-solid fa-box-open'></i>
        </div>
    );

    return (
        <div className="main_view_section">
            <p className="title_main_view">{t.sidebar.properties}</p>
            <div className='property_bar_table_cont'>
                <div className='property_func_bar'>
                    <button className='add_property_btn' onClick={handleShow}>
                        <i className='fa-solid fa-plus'></i>{t.properties.add_property}
                    </button>
                    <div className='filter_input_cont'>
                        <i className='fa-solid fa-magnifying-glass'></i>
                        <input
                            className='search_bar_input'
                            placeholder='Search for something...'
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>

                <div className='property_content'>
                    <PropertiesFilter 
                        unit_type_value={unit_type}
                        title_property_value={title_property}
                        nation_property_value={nation_property}
                        city_property_value={city_property}
                        street_property_value={street_property}
                        number_property_value={number_property}
                        zip_property_value={zip_property}
                        type_property_value={type_property}
                        mt2_property_value={mt2_property}
                        floor_property_value={floor_property}
                        buildin_floors_property_value={buildin_floors_property}
                        energy_class_property_value={energy_class_property}
                        exposure_property_value={exposure_property}
                        heating_property_value={heating_property}
                        external_fixtures_property_value={external_fixtures_property}
                        state_property_value={state_property}
                        construction_year_property_value={construction_year_property}
                        living_property_value={living_property}
                        bedroom_property_value={bedroom_property}
                        kitchen_property_value={kitchen_property}
                        bathroom_property_value={bathroom_property}
                        rent_price_property_value={rent_price_property}
                        currency_property_value={currency_property}
                        expenses_property_value={expenses_property}
                        deposit_property_value={deposit_property}
                        commissions_property_value={commissions_property}
                        commission_type_property_value={commission_type_property}
                        other_details_value={other_details}   
                        onFilterChange={handleFilterChange}                     
                    />
                    <PropertiesMain 
                        properties={filteredProperties}
                        onPropertyDeleted={handlePropertyDeleted} 
                        onPropertieAddedOrUpdated={handlePropertyAdded}
                    />
                </div>
            </div>

            <AddPropertieModal
                show={showModal}
                handleClose={handleClose}
                onPropertieAddedOrUpdated={handlePropertyAdded}
                propertyData={false}
            />
            <PopupMex active={popupActive} type={popupType} onClose={handlePopupClose} />
        </div>
    );
}

export default Properties;
