import React from "react";
import Navbar from "./Navbar";
import MainContent from "./MainContent";
import Footer from "./Footer";
import OnBoardingSection from "./OnBoardingSection";
import PaymentProcess from "./PaymentProcess";
import ManageCalendar from "./ManageCalendar";

function Homepage(){
    return(
        <div>
            <Navbar></Navbar>
            <div className="body_content">
                <MainContent></MainContent>
                <OnBoardingSection></OnBoardingSection>
                <PaymentProcess></PaymentProcess>
                <ManageCalendar></ManageCalendar>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Homepage